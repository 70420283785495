import React from "react";
import { Row, Col } from "antd";
import strings from "./services/Localization/Language";
import { motion } from "framer-motion";
import { ServiceTypeEnum } from "./services/enum";
import AnotherServices from "./AnotherServices";

import "./SoftwareDevelopmentTool.scss";

const SoftwareDevelopmentTool = ({ type }) => {
  return (
    <div
      className="software-background"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8 }}>
      {(type === ServiceTypeEnum.MOBILE_DEV ||
        type === ServiceTypeEnum.WEB_DEV) && (
        <Row style={{ marginTop: "3%" }}>
          <Col span={24}>
            <h3 style={{ textAlign: "center" }}>{strings.service_tool}</h3>
          </Col>
        </Row>
      )}
      {type === ServiceTypeEnum.MOBILE_DEV && (
        <Row
          gutter={[8, 8]}
          className="software-dev-row"
          style={{ padding: "3% 10% 1% 10%" }}>
          <Col xl={6} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/cordova.svg").default}
              alt="Cordova"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Cordova</span>
            </div>
          </Col>
          <Col xl={6} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/ionic.svg").default}
              alt="Ionic"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Ionic</span>
            </div>
          </Col>

          <Col xl={6} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/java.svg").default}
              alt="Java"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Java</span>
            </div>
          </Col>
          <Col xl={6} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/kotlin.svg").default}
              alt="Kotlin"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Kotlin</span>
            </div>
          </Col>
          <Col xl={6} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/react.svg").default}
              alt="React"
              className="software-tool"
            />
            <div className="software-caption">
              <span>React</span>
            </div>
          </Col>
          <Col xl={6} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/typescript.svg").default}
              alt="Typescript"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Typescript</span>
            </div>
          </Col>
          <Col xl={6} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/tailwind.svg").default}
              alt="Tailwind"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Tailwind</span>
            </div>
          </Col>
        </Row>
      )}
      {type === ServiceTypeEnum.WEB_DEV && (
        <Row
          gutter={24}
          className="software-web-dev-row"
          style={{ padding: "3% 10% 1% 10%" }}>
          <Col xl={5} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/angular.svg").default}
              alt="Angular"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Angular</span>
            </div>
          </Col>
          <Col xl={5} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/ionic.svg").default}
              alt="Ionic"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Ionic</span>
            </div>
          </Col>

          <Col xl={5} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/mysql.svg").default}
              alt="MySQL"
              className="software-tool"
            />
            <div className="software-caption">
              <span>MySQL</span>
            </div>
          </Col>
          <Col xl={5} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/nodejs.svg").default}
              alt="Node JS"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Node JS</span>
            </div>
          </Col>
          <Col xl={5} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/react.svg").default}
              alt="React"
              className="software-tool"
            />
            <div className="software-caption">
              <span>React</span>
            </div>
          </Col>
          <Col xl={5} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/tailwind.svg").default}
              alt="Tailwind"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Tailwind</span>
            </div>
          </Col>
          <Col xl={5} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/typescript.svg").default}
              alt="Typescript"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Typescript</span>
            </div>
          </Col>
          <Col xl={5} md={6} className="col-dev">
            <img
              src={require("./assets/tool-icon/vue.svg").default}
              alt="Vue JS"
              className="software-tool"
            />
            <div className="software-caption">
              <span>Vue JS</span>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SoftwareDevelopmentTool;

import React from "react";
import { Row, Col } from "antd";
import strings from "./services/Localization/Language";
import { motion } from "framer-motion";
import ServiceCard from "./component/service/ServiceCard";
import { ServiceTypeEnum } from "./services/enum";

const AnotherServices = ({ currentService }) => {
  return (
    <div initial="offscreen" whileInView="onscreen" viewport={{ amount: 0.8 }}>
      <Row style={{ marginTop: "3%" }}>
        <Col span={24}>
          <h3
            style={{
              color: "var(--primary-color)",
              textAlign: "center",
              marginBottom: "0px",
            }}>
            {strings.service_option}
          </h3>
        </Col>
      </Row>
      {(() => {
        switch (currentService) {
          case ServiceTypeEnum.WEB_DEV:
            return (
              <motion.div
                initial={{ opacity: 0, translateX: 100 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                viewport={{ fallback: true }}
                className="service-type-motion">
                <Row gutter={(16, 16)} style={{ padding: "0px 66px 3% 66px" }}>
                  <Col xl={8} md={24} xs={24} className="service-types">
                    <a href="/mobile-service">
                      <ServiceCard
                        serviceType={ServiceTypeEnum.MOBILE_DEV}></ServiceCard>
                    </a>
                  </Col>
                  <Col xl={8} md={24} xs={24} className="service-types  ">
                    <a href="/design-service">
                      <ServiceCard
                        serviceType={
                          ServiceTypeEnum.GRAPH_DESIGN
                        }></ServiceCard>
                    </a>
                  </Col>
                  <Col xl={8} md={24} xs={24} className="service-types  ">
                    <a href="/consultant-service">
                      <ServiceCard
                        serviceType={ServiceTypeEnum.CONSULT}></ServiceCard>
                    </a>
                  </Col>
                </Row>
              </motion.div>
            );
          case ServiceTypeEnum.MOBILE_DEV:
            return (
              <motion.div
                initial={{ opacity: 0, translateX: 100 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                viewport={{ fallback: true }}
                className="service-type-motion">
                <Row gutter={(16, 16)} style={{ padding: "0px 66px 3% 66px" }}>
                  <Col xl={8} md={24} xs={24} className="service-types">
                    <a href="/website-service">
                      <ServiceCard
                        serviceType={ServiceTypeEnum.WEB_DEV}></ServiceCard>
                    </a>
                  </Col>
                  <Col xl={8} md={24} xs={24} className="service-types">
                    <a href="/design-service">
                      <ServiceCard
                        serviceType={
                          ServiceTypeEnum.GRAPH_DESIGN
                        }></ServiceCard>
                    </a>
                  </Col>
                  <Col xl={8} md={24} xs={24} className="service-types  ">
                    <a href="/consultant-service">
                      <ServiceCard
                        serviceType={ServiceTypeEnum.CONSULT}></ServiceCard>
                    </a>
                  </Col>
                </Row>
              </motion.div>
            );
          case ServiceTypeEnum.GRAPH_DESIGN:
            return (
              <motion.div
                initial={{ opacity: 0, translateX: 100 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                viewport={{ fallback: true }}
                className="service-type-motion">
                <Row gutter={(16, 16)} style={{ padding: "0px 66px 3% 66px" }}>
                  <Col xl={8} md={24} xs={24} className="service-types  ">
                    <a href="/website-service">
                      <ServiceCard
                        serviceType={ServiceTypeEnum.WEB_DEV}></ServiceCard>
                    </a>
                  </Col>
                  <Col xl={8} md={24} xs={24} className="service-types  ">
                    <a href="/mobile-service">
                      <ServiceCard
                        serviceType={ServiceTypeEnum.MOBILE_DEV}></ServiceCard>
                    </a>
                  </Col>
                  <Col xl={8} md={24} xs={24} className="service-types  ">
                    <a href="/consultant-service">
                      <ServiceCard
                        serviceType={ServiceTypeEnum.CONSULT}></ServiceCard>
                    </a>
                  </Col>
                </Row>
              </motion.div>
            );
          case ServiceTypeEnum.CONSULT:
            return (
              <motion.div
                initial={{ opacity: 0, translateX: 100 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                viewport={{ fallback: true }}
                className="service-type-motion">
                <Row gutter={(16, 16)} style={{ padding: "0px 66px 3% 66px" }}>
                  <Col xl={8} md={24} xs={24} className="service-types  ">
                    <a href="/website-service">
                      <ServiceCard
                        serviceType={ServiceTypeEnum.WEB_DEV}></ServiceCard>
                    </a>
                  </Col>
                  <Col xl={8} md={24} xs={24} className="service-types  ">
                    <a href="/mobile-service">
                      <ServiceCard
                        serviceType={ServiceTypeEnum.MOBILE_DEV}></ServiceCard>
                    </a>
                  </Col>
                  <Col xl={8} md={24} xs={24} className="service-types  ">
                    <a href="/design-service">
                      <ServiceCard
                        serviceType={
                          ServiceTypeEnum.GRAPH_DESIGN
                        }></ServiceCard>
                    </a>
                  </Col>
                </Row>
              </motion.div>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default AnotherServices;

import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, Card, message, Select } from "antd";
import languageVar from "./services/Localization/Language";
import { motion } from "framer-motion";
import {
  MailOutlined,
  PhoneOutlined,
  ArrowRightOutlined,
  InfoCircleOutlined,
  QuestionOutlined,
} from "@ant-design/icons";

import { ServiceTypeEnum } from "./services/enum";

import sendEmail from "./api/email";

const ContactUs = ({ language, spinningState }) => {
  const { Meta } = Card;
  const { TextArea } = Input;
  const [messageApi, contextHolder] = message.useMessage();
  const [submitButton, setSubmitButton] = useState(false);
  var submitMessage = "";

  useEffect(() => {
    if (form && window.location.hash.indexOf("?package=") != -1) {
      const packageParams = window.location.hash.replace(
        "#contact-us?package=",
        ""
      );
      form.setFieldsValue({
        service_type: packageParams,
      });
    }
  }, []);

  const onFinish = async (values) => {
    setSubmitButton(true);

    await spinningState();
    const result = await sendEmail(values, language);
    // show loading
    if (result == "failed") {
      messageApi.open({
        type: "error",
        content:
          "Oh no this is embarrassing, the mail sender is in trouble sending your message. Kindly use the live chat feature to satisfy your curiosity.",
        duration: 4,
      });
    } else {
      messageApi.open({
        type: "success",
        content:
          "Email has been sent successfully! We will be in touch shortly, but you may also find your answers by asking via the live chat feature.",
        duration: 4,
      });
      form?.resetFields();
    }
    setSubmitButton(false);
  };

  const onFinishFailed = (errorInfo) => {
    // handle failed
    spinningState();
    messageApi.open({
      type: "error",
      content: submitMessage,
      duration: 4,
    });
    submitMessage = "";
  };

  const [form] = Form.useForm();
  const { Option } = Select;

  const onServiceTypeChange = (value) => {
    form.setFieldsValue({
      service_type: value,
    });
  };

  function getServiceName(serviceType) {
    switch (serviceType) {
      case ServiceTypeEnum.WEB_DEV:
        return languageVar.website_service_title;
      /* WEB_DEV PRICES */
      case ServiceTypeEnum.WEB_DEV_BASIC:
        return `${languageVar.website_service_title} (Basic Package)`;
      case ServiceTypeEnum.WEB_DEV_PRO:
        return `${languageVar.website_service_title} (Professional Package)`;
      case ServiceTypeEnum.WEB_DEV_BUSINESS:
        return `${languageVar.website_service_title} (Business Package)`;
      case ServiceTypeEnum.WEB_DEV_CORP:
        return `${languageVar.website_service_title} (Corporate Package)`;

      case ServiceTypeEnum.MOBILE_DEV:
        return languageVar.mobile_service_title;
      /* MOBILE_DEV PRICES */
      case ServiceTypeEnum.MOBILE_DEV_WEB_VIEW:
        return `${languageVar.mobile_service_title} (Web-view)`;
      case ServiceTypeEnum.MOBILE_DEV_HEALTH:
        return `${languageVar.mobile_service_title} (${languageVar.pricing_mobile.health})`;
      case ServiceTypeEnum.MOBILE_DEV_SHOP:
        return `${languageVar.mobile_service_title} (${languageVar.pricing_mobile.eshope})`;
      case ServiceTypeEnum.MOBILE_DEV_CASHIER:
        return `${languageVar.mobile_service_title} (Cashier)`;

      case ServiceTypeEnum.GRAPH_DESIGN:
        return languageVar.design_service_title;

      case ServiceTypeEnum.CONSULT:
        return languageVar.consultant_service_title;
    }
  }

  return (
    <>
      {contextHolder}
      <div
        className="contact-us-background"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ amount: 0.8 }}>
        <Row className="contact-us-row" justify="center">
          <Col md={24} xl={12}>
            <Row>
              <Col span={24}>
                <motion.div
                  initial={{ opacity: 0, translateX: 100 }}
                  whileInView={{ opacity: 1, translateX: 0 }}
                  viewport={{ fallback: true }}>
                  <h3
                    className="contactus-subtitle"
                    style={{ marginTop: "0px" }}>
                    {languageVar.contact_us_subtitle}
                  </h3>
                  <h1 className="md-title" style={{ marginTop: "15px" }}>
                    {languageVar.contact_us_title}
                  </h1>
                </motion.div>
              </Col>

              <Col span={24}>
                <motion.div
                  initial={{ opacity: 0, translateX: 100 }}
                  whileInView={{ opacity: 1, translateX: 0 }}
                  viewport={{ fallback: true }}>
                  <p>{languageVar.contact_us_desc}</p>
                </motion.div>
              </Col>

              <Col span={24}>
                <motion.div
                  className="contact-us-card"
                  initial={{ opacity: 0, translateX: 100 }}
                  whileInView={{ opacity: 1, translateX: 0 }}
                  viewport={{ fallback: true }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "10px 10px 22px 3px rgba(0,0,0,0.26)",
                    transition: { duration: 0.5, ease: "easeInOut" },
                  }}>
                  <a href={languageVar.send_email_url} target="_blank">
                    <Card
                      style={{
                        marginTop: 16,
                        height: 100,
                      }}>
                      <Meta
                        avatar={
                          <MailOutlined
                            style={{
                              fontSize: "30px",
                              color: "var(--secondary-color)",
                              paddingRight: "0px",
                            }}
                          />
                        }
                        title="Email Us"
                        description="wecare@cs.lumiiumtech.com"
                      />
                    </Card>
                  </a>
                </motion.div>
              </Col>
              <Col span={24}>
                <motion.div
                  className="contact-us-card"
                  initial={{ opacity: 0, translateX: 100 }}
                  whileInView={{ opacity: 1, translateX: 0 }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "10px 10px 22px 3px rgba(0,0,0,0.26)",
                    transition: { duration: 0.5, ease: "easeInOut" },
                  }}
                  viewport={{ fallback: true }}>
                  <a
                    href="https://api.whatsapp.com/send?phone=085174126369"
                    target="_blank">
                    <Card
                      style={{
                        marginTop: 16,
                        height: 100,
                      }}>
                      <Meta
                        avatar={
                          <PhoneOutlined
                            style={{
                              fontSize: "30px",
                              color: "var(--secondary-color)",
                            }}
                          />
                        }
                        title="Contact Us"
                        description="(+62)85174126369"
                      />
                    </Card>
                  </a>
                </motion.div>
              </Col>
              <Col span={24}>
                <motion.div
                  className="contact-us-card"
                  initial={{ opacity: 0, translateX: 100 }}
                  whileInView={{ opacity: 1, translateX: 0 }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "10px 10px 22px 3px rgba(0,0,0,0.26)",
                    transition: { duration: 0.5, ease: "easeInOut" },
                  }}
                  viewport={{ fallback: true }}>
                  <a href="/faq">
                    <Card
                      style={{
                        marginTop: 16,
                        height: 100,
                      }}>
                      <Meta
                        avatar={
                          <QuestionOutlined
                            style={{
                              fontSize: "30px",
                              color: "var(--secondary-color)",
                            }}
                          />
                        }
                        title="FAQ"
                        description="Frequently Asked Questions"
                      />
                    </Card>
                  </a>
                </motion.div>
              </Col>
            </Row>
          </Col>
          <Col md={24} xl={12} xs={24} className="form-col">
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}>
              {form && (
                <Form
                  className="contact-form"
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="customer_name"
                        label={languageVar.contact_field_name}
                        rules={[
                          {
                            required: true,
                            message: languageVar.contact_name_warning,
                          },
                        ]}>
                        <Input
                          allowClear
                          placeholder={languageVar.contact_field_name_desc}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="customer_email"
                    label={languageVar.contact_field_email}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: languageVar.contact_email_warning,
                      },
                    ]}>
                    <Input
                      allowClear
                      placeholder={languageVar.contact_field_email_desc}
                    />
                  </Form.Item>
                  <Form.Item
                    name="company"
                    label={languageVar.contact_field_company}
                    rules={[
                      {
                        required: true,
                        message: languageVar.contact_company_warning,
                      },
                    ]}
                    tooltip={{
                      title: languageVar.contact_warning,
                      icon: <InfoCircleOutlined />,
                    }}>
                    <Input
                      placeholder={languageVar.contact_field_company_desc}
                      allowClear
                    />
                  </Form.Item>

                  <Form.Item
                    name="service_type"
                    label={languageVar.contact_field_service}
                    rules={[
                      {
                        required: true,
                        message: languageVar.contact_service_warning,
                      },
                    ]}>
                    <Select
                      className="service-select"
                      placeholder={languageVar.contact_field_service_desc}
                      onChange={onServiceTypeChange}
                      allowClear
                      style={{
                        backgroundColor: "white !important",
                      }}>
                      {Object.values(ServiceTypeEnum).map((key, index) => (
                        <Option value={key}>{getServiceName(key)}</Option>
                      ))}
                      <Option value="other">
                        {languageVar.contact_service_others}
                      </Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={languageVar.contact_field_message}
                    name="message"
                    style={{ height: "150px" }}
                    rules={[
                      {
                        required: true,
                        message: languageVar.contact_message_warning,
                      },
                    ]}>
                    <TextArea
                      placeholder={languageVar.contact_field_message_desc}
                      style={{ marginBottom: "15px" }}
                      allowClear
                      rows={4}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      disabled={submitButton}
                      type="primary"
                      htmlType="submit"
                      className="btnsubmit">
                      {languageVar.contact_button}
                      <ArrowRightOutlined />
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </motion.div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactUs;

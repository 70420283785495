import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPCollectInfo() {
  return (
    <>
      <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
      <h4>Personal information you disclose to us</h4>
      <p>
        <i>
          <b>In Short:</b> We collect personal information that you provide to
          us.
        </i>
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you express an interest in obtaining information about us or our
        products and Services, when you participate in activities on the
        Services, or otherwise when you contact us.
      </p>
      <p>
        <b>Personal Information Provided by You.</b> The personal information
        that we collect depends on the context of your interactions with us and
        the Services, the choices you make, and the products and features you
        use. The personal information we collect may include the following:
        <ul>
          <li>names</li>
          <li>phone numbers</li>
          <li>email addresses</li>
          <li>mailing addresses</li>
          <li>job titles</li>
          <li>billing addresses</li>
          <li>debit/credit card numbers</li>
        </ul>
      </p>
      <p>
        <b>Sensitive Information.</b> We do not process sensitive information.
      </p>
      <p>
        <b>Payment Data.</b> We may collect data necessary to process your
        payment if you make purchases, such as your payment instrument number,
        and the security code associated with your payment instrument. All
        payment data is stored by GoPay, OVO, DANA, ShopeePay and Paypal. You
        may find their privacy notice link(s) here:
        <a
          href="https://gopay.com.my/privacy-policy/#:~:text=We%20use%20the%20information%20that,whenever%20you%20interact%20with%20us
          "
        >
          gopay,
        </a>
        <a href="https://www.ovo.id/kebijakan-privasi">ovo,</a>
        <a
          href="https://m.dana.id/m/standalone/policy#:~:text=4.3%20DANA%20will%20not%20sell,do%20disclose%20the%20Personal%20Data
          "
        >
          dana,
        </a>
        <a href="https://shopeepay.co.id/privacy">shopeepay,</a> and
        <a href="https://www.paypal.com/us/legalhub/privacy-full?locale.x=en_US">
          paypal.
        </a>
      </p>
      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <h4>Information automatically collected</h4>
      <p>
        <i>
          <b>In Short: </b>Some information — such as your Internet Protocol
          (IP) address and/or browser and device characteristics — is collected
          automatically when you visit our Services.
        </i>
      </p>
      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>
      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies.
      </p>
      <p>
        The information we collect includes:
        <ul>
          <li>
            <b>
              <i>Log and Usage Data.</i>
            </b>{" "}
            Log and usage data is service-related, diagnostic, usage, and
            performance information our servers automatically collect when you
            access or use our Services and which we record in log files.
            Depending on how you interact with us, this log data may include
            your IP address, device information, browser type, and settings and
            information about your activity in the Services (such as the
            date/time stamps associated with your usage, pages and files viewed,
            searches, and other actions you take such as which features you
            use), device event information (such as system activity, error
            reports (sometimes called "crash dumps"), and hardware settings).
          </li>
          <li>
            <b>
              <i>Device Data.</i>
            </b>{" "}
            We collect device data such as information about your computer,
            phone, tablet, or other device you use to access the Services.
            Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </li>
          <li>
            <b>
              <i>Location Data.</i>
            </b>{" "}
            We collect location data such as information about your device's
            location, which can be either precise or imprecise. How much
            information we collect depends on the type and settings of the
            device you use to access the Services. For example, we may use GPS
            and other technologies to collect geolocation data that tells us
            your current location (based on your IP address). You can opt out of
            allowing us to collect this information either by refusing access to
            the information or by disabling your Location setting on your
            device. However, if you choose to opt out, you may not be able to
            use certain aspects of the Services.
          </li>
        </ul>
      </p>

      <h4 id="othersources">Information collected from other sources</h4>
      <p>
        <i>
          <b>In Short: </b>We may collect limited data from public databases,
          marketing partners, and other outside sources.
        </i>
      </p>
      <p>
        In order to enhance our ability to provide relevant marketing, offers,
        and services to you and update our records, we may obtain information
        about you from other sources, such as public databases, joint marketing
        partners, affiliate programs, data providers, and from other third
        parties. This information includes mailing addresses, job titles, email
        addresses, phone numbers, intent data (or user behavior data), Internet
        Protocol (IP) addresses, social media profiles, social media URLs, and
        custom profiles, for purposes of targeted advertising and event
        promotion.
      </p>
    </>
  );
}

export default PPCollectInfo;

import { Row, Col } from "antd";
import {
  WhatsAppOutlined,
  MailOutlined,
  InstagramOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import strings from "./services/Localization/Language";

const CompanyFooter = () => {
  return (
    <footer>
      <Row className="company-footer">
        <Col xs={24} md={9} style={{ padding: "12px 12px 12px 40px" }}>
          <div>
            <div>
              <img
                src={require("./assets/logo/logo_horizontal.png")}
                style={{
                  width: "120px",
                  marginLeft: "25px",
                  paddingTop: "10px",
                }}
              />
            </div>
            <div className="company-desc">
              <p>{strings.footer_company_desc}</p>
              <br />
              <a href="/privacy-policy" style={{ paddingLeft: "0" }}>
                <u>Privacy Policy & Cookies</u>
              </a>
              <a href="/faq" style={{ paddingLeft: "0" }}>
                <u>FAQ</u>
              </a>
            </div>
          </div>
        </Col>
        <Col className="company-col" span={4}>
          <h3 className="footerText">Company</h3>
          <div>
            <a href="/#about-us" className="footerText">
              {strings.about_us}
            </a>
            <a href="/#services" className="footerText">
              {strings.services}
            </a>
            <a id="gtm-contact-us" href="/#contact-us" className="footerText">
              {strings.contact_us}
            </a>
          </div>
          <h3
            className="footerText"
            style={{
              paddingTop: "35px",
            }}>
            {strings.services}
          </h3>
          <div>
            <a href="/website-service" className="footerText">
              {strings.website_service_title}
            </a>
            <a href="/mobile-service" className="footerText">
              {strings.mobile_service_title}
            </a>
            <a href="/consultant-service" className="footerText">
              {strings.consultant_service_title}
            </a>
            <a href="/design-service" className="footerText">
              {strings.design_service_title}
            </a>
          </div>
        </Col>
        <Col className="contact-col" xs={24} md={11}>
          <h3 className="footerText">{strings.footer_contact_title}</h3>
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=6285174126369"
              className="footerText"
              target="_blank">
              <WhatsAppOutlined />
              (+62)85174126369
            </a>
            <a
              href={strings.send_email_url}
              className="footerText"
              target="_blank">
              <MailOutlined />
              wecare@cs.lumiiumtech.com
            </a>
            <a
              href="http://instagram.com/_u/lumiiumtech/"
              className="footerText"
              target="_blank">
              <InstagramOutlined />
              @lumiiumtech
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100087171504234"
              className="footerText"
              target="_blank">
              <FacebookOutlined />
              LumiiumTech
            </a>
          </div>
          <div>
            <a
              className="blog-logo"
              href="https://lumiiumtech.com/blog/"
              target="_blank">
              <img src={require("./assets/logo/logo_media.png")} />
            </a>
            <p className="company-desc blog-desc">{strings.footer_media}</p>
          </div>
        </Col>

        <Col
          span={24}
          style={{ color: "var(--white-color)", textAlign: "center" }}>
          © Copyright {new Date().getFullYear()} - LumiiumTech
        </Col>
      </Row>
    </footer>
  );
};

export default CompanyFooter;

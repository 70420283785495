let generalText = {
  en: {
    about_us: "About Us",
    services: "Services",
    contact_us: "Contact Us",
    faq: {
      service_desc:
        "You can also visit the FAQ page to find all the questions visitors frequently ask.",
      cta: "See All",
      desc: "We try to help you find answers to questions that are frequently asked by visitors.",
    },
  },
  id: {
    about_us: "Tentang Kami",
    services: "Layanan",
    contact_us: "Kontak Kami",
    faq: {
      service_desc:
        "Anda juga dapat mengunjungi halaman FAQ untuk menemukan semua pertanyaan yang sering diajukan pengunjung.",
      cta: "Lihat Semua",
      desc: "Kami berusaha membantu Anda menemukan jawaban terkait pertanyaan yang sering ditanyakan oleh pengunjung.",
    },
  },
};

let notFoundText = {
  en: {
    about_us: "About Us",
    services: "Services",
    contact_us: "Contact Us",
  },
  id: {
    about_us: "Tentang Kami",
    services: "Layanan",
    contact_us: "Kontak Kami",
  },
};

let footerText = {
  en: {
    footer_company_desc:
      "LumiiumTech is a software house that's here to help you navigate the digital landscape. No matter what your needs are, we can offer you a custom-designed solution. We’re dedicated to customer happiness and easiness of using the software, makes us different from other custom software development companies.",
    footer_contact_title: "Get in Touch with Us",
    footer_media:
      "LumiiumTech Media is a blog site that provides the latest articles about technology. It has a vast collection of informative articles that would make you like reading. LumiiumTech Media provides its readers latest and updated Technology, Tech News, and Tech Reviews.",
  },
  id: {
    footer_company_desc:
      "LumiiumTech adalah software house yang ada untuk membawamu ke arah digitalisasi. Apapun yang kamu butuhkan, kami memiliki solusi digital yang dibuat khusus untukmu. Kami mendedikasikan diri untuk kemudahan penggunaan dan kepuasan pengguna, itulah yang membedakan kami dari software house lainnya.",
    footer_contact_title: "Hubungi Kami",
    footer_media:
      "LumiiumTech Media adalah situs blog yang menyediakan artikel-artikel terbaru seputar teknologi. LumiiumTech Media memiliki banyak koleksi artikel informatif yang akan membuat Anda suka membaca. LumiiumTech Media memberi para pembacanya Teknologi, Berita Teknologi, dan Ulasan Teknologi terbaru dan terupdate.",
  },
};

let generalSendEmail = {
  en: {
    send_email_url:
      "https://mail.google.com/mail/?view=cm&fs=1&to=wecare@cs.lumiiumtech.com&su=Question about [service]&body=Hi there! I'm [name] from [company]. I want to ask about ...",
  },
  id: {
    send_email_url:
      "https://mail.google.com/mail/?view=cm&fs=1&to=wecare@cs.lumiiumtech.com&su= Pertanyaan tentang [layanan]&body=Halo! Saya [nama] dari [perusahaan]. Saya ingin bertanya tentang ...",
  },
};

var generalfaqItems = {
  en: {
    general_faq_items: [
      {
        header:
          "Can the data on all work carried out by LumiiumTech be known by other parties?",
        text: `We always maintain the confidentiality of ideas and data related to customer projects. We can also issue a Non Disclosure Agreement (NDA) if necessary to protect customer data from being leaked to third parties as a sign of our responsibility for the confidentiality of the projects we handle.`,
      },
      {
        header:
          "Does LumiiumTech provide website and mobile application maintenance services?",
        text: `The maintenance services that we provide are optional, meaning that customers can choose to use or not use the service. We offer 2 types of maintenance methods, namely:\n
        <ul>
          <li>Routine maintenance : maintenance that is carried out every month.</li>
          <li>Maintenance calls : maintenance that is carried out if the customer requires it.</li>
        </ul>`,
      },
      {
        header: "Can LumiiumTech create custom websites or mobile apps?",
        text: `Yes we absolutely can. We will be happy to help meet your needs. If none of our packages suit your needs, consult us now for custom services.`,
      },
      {
        header: "Can LumiiumTech provide services throughout Indonesia?",
        text: `Certainly we can. We present online services  so that they can be accessed anytime and anywhere.`,
      },
      {
        header:
          "How long does it take to create a website, mobile app, and design?",
        text: `Website, mobile application and design varies greatly, depending on the complexity and level of difficulty of the creation. We will inform you about the estimated processing time once we know the details the customer wants.`,
      },
      {
        header: "What are the terms and methods of payment?",
        text: `For now, the payment terms are divided into 2, for nominal transactions below IDR 5,000,000 and above IDR 5,000,000.\n
        <ul>
          <li> Less than IDR 5,000,000: Payment is made twice, (1) Deposit of 30% of the total fee and (2) 70% of the total fee is paid when the service is finished.</li>
          <li> More than IDR 5,000,000: Payment is made 3 times, (1) Deposit of 25% of the total fee, (2) 25% of the total fee when the service has reached 50% progress, and (3) 50% of the total fee is paid when the service is finished.</li>
        </ul>\nFor the payment method, we provide payment via: Paypal, BCA Bank Transfer, Gopay, OVO, Shopee Pay, and Funds.`,
      },
    ],
  },
  id: {
    general_faq_items: [
      {
        header:
          "Apakah data-data yang ada pada seluruh pengerjaan yang dilakukan LumiiumTech bisa diketahui oleh pihak-pihak lain?",
        text: `Kami senantiasa menjaga kerahasiaan ide maupun data-data yang berhubungan dengan project pelanggan. Kami juga dapat mengeluarkan Non Disclosure Agreement (NDA) apabila diperlukan untuk menjaga data-data pelanggan agar tidak bocor kepada pihak ketiga sebagai tanda tanggung jawab kami terhadap kerahasiaan project yang kami tangani.`,
      },
      {
        header:
          "Apakah LumiiumTech menyediakan jasa maintenance website dan aplikasi mobile?",
        text: `Layanan maintenance yang kami berikan bersifat opsional, artinya pelanggan bisa memilih untuk menggunakan atau tidak menggunakan layanan tersebut. Kami menawarkan 2 jenis metode maintenance yaitu :\n
        <ul>
          <li>Maintenance rutin yang dilakukan setiap bulan.</li>
          <li>Maintenance panggilan, yaitu maintenance yang dilakukan jika pelanggan membutuhkan.</li>
        </ul>`,
      },
      {
        header:
          "Apakah LumiiumTech bisa membuat website atau mobile apps custom?",
        text: `Sangat bisa. Kami dengan senang hati akan membantu memenuhi kebutuhanmu. Jika dirasa tidak ada paket kami yang sesuai dengan kebutuhan Anda, konsultasikan dengan kami sekarang untuk layanan custom.`,
      },
      {
        header:
          "Apakah LumiiumTech dapat memberikan layanan untuk seluruh indonesia?",
        text: `Tentu bisa. Kami menghadirkan layanan secara online sehingga dapat diakses kapanpun dan dimanapun.`,
      },
      {
        header:
          "Berapa lama waktu yang dibutuhkan untuk pembuatan website, aplikasi mobile, dan desain?",
        text: `Waktu pembuatan website, aplikasi mobile, dan desain sangat bervariasi, tergantung dengan kompleksitas dan tingkat kesulitan pembuatannya. Estimasi waktu pengerjaan akan kami informasikan setelah kami mengetahui detail yang diinginkan pelanggan.`,
      },
      {
        header: "Bagaimana termin dan metode pembayaran?",
        text: `Untuk saat ini, termin pembayaran terbagi menjadi 2, yaitu untuk nominal transaksi di bawah Rp 5.000.000 dan di atas Rp 5.000.000.\n
        <ul>
          <li> Di bawah Rp 5.000.000 : Pembayaran dilakukan sebanyak 2 kali, yaitu DP sebesar 30% dari total biaya dan sisanya dibayarkan ketika layanan selesai.</li>
          <li> Di atas Rp 5.000.000 : Pembayaran dilakukan sebanyak 3 kali, yaitu DP sebesar 25% dari total biaya, 25% dari total biaya saat layanan sudah mencapai progress 50%, dan sisanya dibayarkan ketika layanan selesai.</li>
        </ul>\nSementara itu untuk metode pembayaran, kami menyediakan pembayaran melalui : 
        Paypal, Transfer Bank BCA, Gopay, OVO, Shopee Pay, dan Dana.
        `,
      },
    ],
  },
};

export default {
  generalText,
  notFoundText,
  footerText,
  generalfaqItems,
  generalSendEmail,
};

let servicesText = {
  en: {
    services_title: "Tell Us Your Craziest Idea!",
    services_subtitle: "We’ll light it up!",
    services_desc:
      "LumiiumTech provides digital solutions 100% based on your needs. We’re dedicated to build hi-quality products and ensuring customer happiness and easiness of using it.",
    services_offer: "Discuss your needs now",
    services_offer_desc:
      "It’s time for our digital solutions to help you grow your potential and your business.",
    service_web_dev:
      "We offer high-quality website development including simple websites and web-based applications. We design, build, and maintain the web tailored for your needs. The benefits are increasing credibility, brand awareness, sales, and facilitating various activities for you or your company.",
    service_mobile_dev:
      "Tell us what your problem is or what application you need, we will provide the best solution in android or iOS application, or even cross platform. This mobile application can be for personal or business.",
    service_consult:
      "Have a problem with digitalization? We can help you with consulting services consisting of discussing needs to systems design (tech stack, data flow, user stories, and others). We have experienced and trusted teams to provide solutions to your problems.",
    service_design:
      "If you need creative, unique, and attractive design services, we can help you. Our services include 2d and 3d design such as illustrations, logos, layouts, social media kits, 3d models, and many more.",
    service_benefit: "Why We Choose LumiiumTech ?",
    service_tool: "Our Development Tools",
    service_option: "Other services",
    service_detail_message: "What we can do :",
    service_detail_quote: "Get a quote",
  },
  id: {
    services_title: "Beri Tahu Kami Ide Gilamu!",
    services_subtitle: "We’ll light it up!",
    services_desc:
      "LumiiumTech memberikan solusi digital 100% berdasarkan kebutuhanmu. Kami mendedikasikan diri untuk membuat produk yang berkualitas dan menekankan pada kemudahan penggunaan dan kepuasan pengguna.",
    services_offer: "Diskusikan kebutuhan kamu sekarang",
    services_offer_desc:
      "    Saatnya solusi digital kami membantu Anda untuk meningkatkan potensi diri maupun bisnis Anda.",
    service_web_dev:
      "Kami menawarkan jasa pembuatan website berkualitas tinggi mencakup website sederhana hingga aplikasi berbasis web mulai dari desain, pembuatan, hingga pemeliharaan. Dengan ini kamu bisa meningkatkan kredibilitas, brand awareness, penjualan, hingga mempermudah berbagai aktivitas kamu atau perusahaanmu.",
    service_mobile_dev:
      "Beritahu kami apa masalahmu atau aplikasi apa yang sedang kamu butuhkan, kami akan memberikan solusi terbaik melalui aplikasi android atau iOS, atau bahkan keduanya. Aplikasi mobile ini bisa untuk kebutuhan personal hingga bisnis.",
    service_consult:
      "Punya masalah yang berkaitan dengan digitalisasi? Kami bisa membantumu dengan layanan konsultasi mulai dari diskusi kebutuhan hingga mendesain sistem (tech stack, data flow, user story, dan lainnya). Kami memiliki tim yang berpengalaman dan terpercaya untuk memberi solusi atas masalahmu.",
    service_design:
      "Jika kamu sedang butuh layanan pembuatan desain yang kreatif, unik, dan menarik, kami bisa membantumu. Layanan yang kami miliki mencakup desain 2d dan 3d seperti ilustrasi, logo, layout, socmed kit, 3d model, dan lainnya.",
    service_benefit: "Mengapa harus memilih LumiiumTech ?",
    service_tool: "Alat Pengembangan Kami",
    service_option: "Layanan Lainnya",
    service_detail_message: "Yang kami bisa lakukan :",
    service_detail_quote: "Dapatkan Penawaran",
  },
};

export default servicesText;

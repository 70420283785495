import React from "react";
import { Button, Row, Col, Card } from "antd";
import strings from "./services/Localization/Language";
import { motion } from "framer-motion";
import SoftwareDevelopmentTool from "./SoftwareDevelopmentTool";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  BulbOutlined,
  HeartOutlined,
  ExperimentOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import AnotherServices from "./AnotherServices";
import FAQServices from "./component/faq/faq-services/faq-services";

import { DesignAdvantages, ServiceTypeEnum } from "./services/enum";

const DesignService = () => {
  return (
    <div
      className="detail-service-background"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8 }}>
      <Row className="detail-service">
        <Col xl={16} md={24}>
          <motion.div
            initial={{ opacity: 0, translateX: 100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ fallback: true }}>
            <h1 className="home-title">{strings.design_service_title}</h1>
            <div className="service-img-mobile">
              <img
                src={require("./assets/service/design.png")}
                className="detail-consultant-service"
                alt="design"
              />
            </div>
            <p>{strings.design_service_desc}</p>
            <p style={{ marginTop: "15px" }}>
              {strings.design_service_subdesc}
            </p>
            <p className="service-detail-message">
              {strings.service_detail_message}
            </p>
            <Row className="list-detail-service">
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>2D Graphic Design</span>
              </Col>
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Logo, Illustration, Social Media kits, Layout, etc</span>
              </Col>

              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>3D Graphic Design</span>
              </Col>

              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>3D model, 3D animation</span>
              </Col>

              <Col span={24}>
                <Button
                  id="gtm-contact-us"
                  className="button-home"
                  type="primary"
                  onClick={() => (window.location.href = "/#contact-us")}>
                  {strings.service_detail_quote}
                  <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </motion.div>
        </Col>
        <Col xl={8} md={24} style={{ textAlign: "center" }}>
          <motion.div
            initial={{ opacity: 0, translateX: 100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ fallback: true }}>
            <div>
              <img
                src={require("./assets/service/design.png")}
                className="service-img"
                alt="design"
              />
            </div>
          </motion.div>
        </Col>
      </Row>
      <Row style={{ marginTop: "3%" }}>
        <Col span={24}>
          <h3 style={{ color: "var(--primary-color)", textAlign: "center" }}>
            {strings.service_benefit}
          </h3>
        </Col>
      </Row>
      <Row
        className="detail-service-feature"
        style={{ justifyContent: "center" }}
        gutter={[48, 24]}>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <CheckCircleOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{DesignAdvantages[0]}</span>
          </Card>
        </Col>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <BulbOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{DesignAdvantages[1]}</span>
          </Card>
        </Col>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <ExperimentOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{DesignAdvantages[2]}</span>
          </Card>
        </Col>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <HeartOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{DesignAdvantages[3]}</span>
          </Card>
        </Col>
      </Row>
      <SoftwareDevelopmentTool type={ServiceTypeEnum.GRAPH_DESIGN} />
      <FAQServices type={ServiceTypeEnum.GRAPH_DESIGN}></FAQServices>
      <AnotherServices currentService={ServiceTypeEnum.GRAPH_DESIGN} />
    </div>
  );
};

export default DesignService;

let aboutUsText = {
  en: {
    about_us_title: "We're Here to Help",
    about_us_desc:
      "LumiiumTech knows that the world has changed, and we've got your back. We're a software house that's here to help you navigate the digital landscape. No matter what your needs are, we can offer you a custom-designed solution.",
    about_us_desc2:
      "LumiiumTech is an experienced team of software developers. We’re dedicated to customer happiness and easiness of using the software, makes us different from other custom software development companies.",
  },
  id: {
    about_us_title: "Kami Ada untuk Membantumu.",
    about_us_desc:
      "LumiiumTech mengerti dunia telah berubah ke arah digital. Kami adalah software house yang ada untuk membawamu memasukinya. Apapun yang kamu butuhkan, kami memiliki solusi digital yang dibuat khusus untukmu.",
    about_us_desc2:
      "LumiiumTech terdiri dari tim yang berpengalaman dalam software development. Kami mendedikasikan diri untuk kemudahan penggunaan dan kepuasan pengguna, itulah yang membedakan kami dari software house lainnya.",
  },
};

export default aboutUsText;

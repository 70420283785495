import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPContactInfo() {
  return (
    <>
      <h3>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>

      <p>
        If you have questions or comments about this notice, you may email us at
        wecare@cs.lumiiumtech.com or by post to:
        <br />
        <br />
        LumiiumTech
        <br />
        Bandung, Indonesia
        <br />
        Bandung, Jawa Barat
        <br /> Indonesia
      </p>
    </>
  );
}
export default PPContactInfo;

export const ServiceTypeEnum = {
  WEB_DEV: "website",
  WEB_DEV_BASIC: "website_basic",
  WEB_DEV_PRO: "website_pro",
  WEB_DEV_BUSINESS: "website_business",
  WEB_DEV_CORP: "website_corp",
  MOBILE_DEV: "mobile",
  MOBILE_DEV_WEB_VIEW: "mobile_web_view",
  MOBILE_DEV_SHOP: "mobile_eshop",
  MOBILE_DEV_HEALTH: "mobile_health",
  MOBILE_DEV_CASHIER: "mobile_cashier",
  CONSULT: "consult",
  GRAPH_DESIGN: "design",
};

export const WebPackageEnum = {
  basic: {
    is_recomended: false,
    // Price
    normal_price: 1600000,
    discount_price: 1500000,
    extend_price: 700000,

    // Basic info
    domain: ".com | .org | .info",
    visitor: 15000,
    SEO: "Basic",
    total_email: "1",
    is_SSL: true,
    total_page: 1,
    total_maintenance: 1,

    // Advance
    total_revision: 1,

    total_article: 0,
    is_multi_lang: false,
    is_hotjar: false,
    is_google_analytic: false,
    chat: null,
    is_anti_copy: true,
    design_type: "Basic",
    set_up_wp: false,
  },

  professional: {
    is_recomended: true,

    // Price
    normal_price: 2400000,
    discount_price: 2200000,
    extend_price: 1300000,

    // Basic info
    domain: ".com | .org | .info",
    visitor: 60000,
    SEO: "Basic",
    total_email: "Unlimited",
    is_SSL: true,
    total_page: 5,
    total_maintenance: 1,

    // Advance

    total_revision: 1,
    total_article: 0,
    is_hotjar: false,
    is_multi_lang: false,
    is_google_analytic: false,
    chat: "Whatsapp",
    is_anti_copy: true,
    design_type: "Medium",
    set_up_wp: true,
  },

  business: {
    is_recomended: false,

    // Price
    normal_price: 4700000,
    discount_price: 4500000,
    extend_price: 2100000,

    // Basic info
    domain: ".com | .org | .info | .net | .id",
    visitor: 135000,
    SEO: "Medium",
    total_email: "Unlimited",
    is_SSL: true,
    total_page: 10,
    total_maintenance: 2,

    // Advance
    total_revision: 2,

    total_article: 0,
    is_multi_lang: true,
    is_hotjar: true,
    is_google_analytic: true,
    chat: "Whatsapp/Tawk",
    is_anti_copy: true,
    design_type: "Medium",
    set_up_wp: true,
  },

  corporate: {
    is_recomended: true,

    // Price
    normal_price: 8200000,
    discount_price: 8000000,
    extend_price: 2900000,

    // Basic info
    domain: ".com | .org | .info | .net | .co.id | .id",
    visitor: 180000,
    SEO: "Professional",
    total_email: "Unlimited",
    is_SSL: true,
    total_page: 15,
    total_maintenance: 3,

    // Advance
    total_article: 2,
    total_revision: 3,

    is_multi_lang: true,
    is_hotjar: true,
    is_google_analytic: true,
    chat: "Whatsapp/Tawk",
    is_anti_copy: true,
    design_type: "Pro/custom",
    set_up_wp: true,
  },
};

export const WebAdvantages = [
  "SEO Friendly",
  "User Friendly",
  "Interactive",
  "High User Traffics",
];

export const MobileAdvantages = [
  "Scalable",
  "User Friendly",
  "Innovative",
  "Secure",
];

export const DesignAdvantages = [
  "Original",
  "Creative",
  "Innovative",
  "Beauty",
];

export const ConsultantAdvantages = [
  "Work Together",
  "Solution",
  "Analytical",
  "Trusted",
];

import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPShareInfo() {
  return (
    <>
      <h3>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
      <p>
        <i>
          <b>In Short:</b> We may share information in specific situations
          described in this section and/or with the following third parties.
        </i>
      </p>
      <p>
        <b>Vendors, Consultants, and Other Third-Party Service Providers.</b> We
        may share your data with third-party vendors, service providers,
        contractors, or agents (<b>"third parties"</b>) who perform services for
        us or on our behalf and require access to such information to do that
        work. The third parties we may share personal information with are as
        follows:
        <ul>
          <li>
            <b>Communicate and Chat with Users</b> <br />
            Tawk.to
          </li>
          <li>
            <b>Content Optimization</b> <br />
            Google Site Search
          </li>
          <li>
            <b>Functionality and Infrastructure Optimization</b> <br />
            Termly.io
          </li>
          <li>
            <b>Invoice and Billing</b> <br />
            GoPay, DANA, OVO, ShopeePay and PayPal
          </li>

          <li>
            <b>Social Media Sharing and Advertising</b> <br />
            Facebook advertising and Instagram advertising
          </li>

          <li>
            <b>Web and Mobile Analytics</b> <br />
            Google Analytics, Hotjar , Google Ads and Google Tag Manager
          </li>
          <li>
            <b>Social Media Sharing and Advertising</b> <br />
            Niagahoster and WordPress.com
          </li>
        </ul>
      </p>
    </>
  );
}
export default PPShareInfo;

import React, { useEffect } from "react";
import { useCookies } from "react-cookie";

function Splash() {
  const [cookies, setCookie, removeCookie] = useCookies(["visited"]);

  useEffect(() => {
    const screen = document.querySelector(".splash-screen");
    let expires = new Date();
    expires.setDate(expires.getDate() + 1);

    if (!cookies.visited || cookies.visited == "false") {
      setTimeout(() => {
        screen.classList.add("splash-close");
        setCookie("visited", "true", { path: "/", expires });
      }, 2000);
    } else {
      screen.classList.add("splash-close");
    }
  }, []);

  return (
    <div className="splash-screen">
      <div className="splash-animation-container">
        <img
          src={require("./assets/gif/LT-splash.gif")}
          className="splash-animation"
        />
      </div>
    </div>
  );
}

export default Splash;

import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPKeepInfo() {
  return (
    <>
      <h3>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
      <p>
        <i>
          <b>In Short:</b> We keep your information for as long as necessary to
          fulfill the purposes outlined in this privacy notice unless otherwise
          required by law.
        </i>
      </p>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). When we have no ongoing
        legitimate business need to process your personal information, we will
        either delete or anonymize such information, or, if this is not possible
        (for example, because your personal information has been stored in
        backup archives), then we will securely store your personal information
        and isolate it from any further processing until deletion is possible.
      </p>
    </>
  );
}
export default PPKeepInfo;

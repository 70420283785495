import LocalizedStrings from "react-localization";

/* Import app localization */
import generalText from "./localization-component/general-localization-component";
import homeText from "./localization-component/home-localization-component";
import aboutUsText from "./localization-component/about-localization-component";
import visionMissionText from "./localization-component/vimi-localization-component";
import servicesText from "./localization-component/services-localization-component";
import contactUsText from "./localization-component/contact-localization-component";
import mobileServiceText from "./localization-component/service-detail/mobile-service-localization-component";
import websiteServiceText from "./localization-component/service-detail/web-service-localization-component";
import ConsultantServiceText from "./localization-component/service-detail/consult-service-localization-component";
import DesignServiceText from "./localization-component/service-detail/design-service-localization-component";
import pricingWebText from "./localization-component/pricings/pricing-web-localization-component";
import pricingMobileText from "./localization-component/pricings/pricing-mobile-localization-component";

let strings = new LocalizedStrings({
  en: {
    ...generalText.generalText.en,
    ...homeText.en,
    ...aboutUsText.en,
    ...visionMissionText.en,
    ...servicesText.en,
    ...contactUsText.en,
    ...generalText.footerText.en,
    ...generalText.notFoundText.en,
    ...generalText.generalSendEmail.en,
    //Service Detail Component
    ...mobileServiceText.mobileServiceText.en,
    ...websiteServiceText.websiteServiceText.en,
    ...DesignServiceText.DesignServiceText.en,
    ...ConsultantServiceText.ConsultantServiceText.en,
    //FAQ
    ...generalText.generalfaqItems.en,
    ...websiteServiceText.websiteServicefaqItems.en,
    ...mobileServiceText.mobileServicefaqItems.en,
    ...ConsultantServiceText.ConsultServicefaqItems.en,
    ...DesignServiceText.DesignServicefaqItems.en,
    //Pricings
    ...pricingWebText.en,
    ...pricingMobileText.en,
  },

  id: {
    ...generalText.generalText.id,
    ...homeText.id,
    ...aboutUsText.id,
    ...visionMissionText.id,
    ...servicesText.id,
    ...contactUsText.id,
    ...generalText.footerText.id,
    ...generalText.notFoundText.id,
    ...generalText.generalSendEmail.id,
    //Service Detail Component
    ...mobileServiceText.mobileServiceText.id,
    ...websiteServiceText.websiteServiceText.id,
    ...DesignServiceText.DesignServiceText.id,
    ...ConsultantServiceText.ConsultantServiceText.id,
    //FAQ
    ...generalText.generalfaqItems.id,
    ...websiteServiceText.websiteServicefaqItems.id,
    ...mobileServiceText.mobileServicefaqItems.id,
    ...ConsultantServiceText.ConsultServicefaqItems.id,
    ...DesignServiceText.DesignServicefaqItems.id,
    //Pricings
    ...pricingWebText.id,
    ...pricingMobileText.id,
  },
});

export default strings;

let pricingWebText = {
  en: {
    pricing_web: {
      title: "The Right Plan for Your Needs",
      desc: "We offer various choices of website develpoment packages to suit your needs. Start to realize your dream website with LumiiumTech and enjoy the benefits.",
      contact_us: "contact us here",
      see_more: "See More Features",
      see_less: "See Less Features",
      best_feature: "Best Feature",
      other_feature: "Other Feature",
      basic_package_desc: "For those who just starting out.",
      pro_package_desc: "Best for growing businesses that want to do more.",
      business_package_desc:
        "Complete package for big business with analytic integration.",
      corp_package_desc:
        "Premium package for corporate with complete analytic integration.",
      contact_us_plan: "Still unsure or got some question? ",
    },
    pricing_web_card: {
      extend_desc: "Renewal fee ",
      order_now: "Order Now!",
      price_desc: "*PPN 11% are not included",
      free: "Free",
      year: "year",
      month: "month",
      visitor: "Max visitors",
      certificate: "certificate",
      total_page: "Total Web pages",
      total_revision: "Design revision",
      chat: "Chat feature",
      dual_lang: "Multi language",
      article: "article",
      email: "Professional email",
      visitor: "Max visitor",
    },
  },
  id: {
    pricing_web: {
      title: "Paket Terbaik untuk Kebutuhan Anda",
      desc: "Kami menawarkan beragam pilihan paket terbaik untuk menyesuaikan kebutuhan Anda. Mulailah untuk mewujudkan website impian Anda bersama LumiiumTech dan nikmati manfaatnya.",
      contact_us: "hubungi kami disini",
      see_more: "Lihat Fitur Lainnya",
      see_less: "Lihat Lebih Sedikit",
      best_feature: "Fitur Unggulan",
      other_feature: "Fitur Lainnya",
      basic_package_desc: "Cocok bagi yang baru memulai.",
      pro_package_desc:
        "Cocok untuk bisnis berkembang yang ingin berbuat lebih banyak.",
      business_package_desc:
        "Paket lengkap untuk bisnis besar dengan integrasi analitik.",
      corp_package_desc:
        "Paket premium untuk perusahaan dengan integrasi analitik lengkap.",
      contact_us_plan: "Masih bingung atau memiliki pertanyaan? ",
    },
    pricing_web_card: {
      extend_desc: "Biaya perpanjang ",
      order_now: "Pesan Sekarang!",
      price_desc: "*Harga belum termasuk PPN 11%",
      free: "Gratis",
      year: "tahun",
      month: "bulan",
      certificate: "sertifikat",
      total_page: "Jumlah halaman web",
      total_revision: "Revisi desain",
      chat: "Fitur chat",
      dual_lang: "Dual bahasa",
      article: "artikel",
      email: "Email professional",
      visitor: "Pengunjung maksimal",
    },
  },
};

export default pricingWebText;

import { Row, Col } from "antd";
import strings from "./services/Localization/Language";
import { motion } from "framer-motion";

const AboutUs = () => {
  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8 }}
    >
      <div className="aboutus-background">
        <Row className="aboutus">
          <Col md={12} xs={20}></Col>
          <Col md={12} xs={20}>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}
            >
              <h3 className="aboutus-subtitle">{strings.about_us}</h3>
              <h1 className="aboutus-title">{strings.about_us_title}</h1>
            </motion.div>
          </Col>
          <Col md={12} xs={20}></Col>
          <Col md={12} xs={20}>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}
            >
              <p className="aboutus-desc">{strings.about_us_desc}</p>
              <p className="aboutus-desc">{strings.about_us_desc2}</p>
            </motion.div>
          </Col>
        </Row>
      </div>
      <div className="visimisi-background">
        <Row className="visimisi" justify="end">
          {/* <Col span={24} className={'l-50'}>
          <h3 className="visimisi-subtitle">About Us</h3>
        </Col> */}

          <Col md={12} xs={20}></Col>
          <Col md={12} xs={20}>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}
            >
              <h1 className="visimisi-title">{strings.vision_title}</h1>
            </motion.div>
          </Col>
          <Col md={12} xs={20}></Col>
          <Col md={12} xs={20}>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}
            >
              <p className="visimisi-desc">{strings.vision_desc}</p>
            </motion.div>
          </Col>
          <Col md={12} xs={20}></Col>
          <Col md={12} xs={20}>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}
            >
              <h1 className="visimisi-title">{strings.mission_title}</h1>
            </motion.div>
          </Col>
          <Col md={12} xs={20}></Col>
          <Col md={12} xs={20}>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}
            >
              <p className="visimisi-desc">{strings.mission_desc}</p>
            </motion.div>
          </Col>
          {/* <Col span={24} >
          <h1 className="visimisi-title">How we can reach our Vision?</h1>
        </Col>
        <Col md={12} xs={20} >
          <p className="visimisi-desc">
          Become a strong foundation for the growth and development of society through digital solutions.
          </p>
        </Col> */}
        </Row>
      </div>
      {/* <StyledAboutUs
        className={"aboutus"}
        id="about-us"
        // style={{ backgroundImage: `url(data:image/svg+xml;base64,${btoa(WhyImage)})` }}
      >
        <Row>
          <img
            className="imgWhy"
            src={require("./assets/aboutus/why.svg").default}
            alt={""}
          />
          <h1 className="titleWhy">
            We <br></br>Believe
          </h1>
          <h2 className="descWhy">
            that digital solution can{" "}
            <span className={"highlightText"}>help people</span> needs
          </h2>
          <img
            className="dotWhy"
            src={require("./assets/aboutus/dot.svg").default}
            alt={""}
          />
        </Row>
      </StyledAboutUs>
      <StyledAboutUs>
        <Row className={"w-100 mt-25"}>
          <Col span={12}>
            <h1 className="titleHow">How?</h1>
            <h2 className="descHow">
              We offer a <span className={"highlightText"}>serviceable</span>{" "}
              digital solution that{" "}
              <span className={"highlightText"}>uniquely</span> design and{" "}
              <span className={"highlightText"}>simple</span> to use{" "}
            </h2>
            <img
              className="triangleHow"
              src={require("./assets/aboutus/triangle.svg").default}
              alt={""}
            />
          </Col>
          <Col span={12}>
            <img
              className="imgHow"
              src={require("./assets/aboutus/how.svg").default}
              alt={""}
            />
          </Col>
        </Row>
      </StyledAboutUs>
      <StyledAboutUs>
        <Row className={"w-100 mt-25"}>
          <img
            className="imgWhat"
            src={require("./assets/aboutus/what.svg").default}
            alt={""}
          />
          <h1 className="titleWhat">So</h1>
          <h2 className="descWhat">
            Here We are to help <br></br>people with their{" "}
          </h2>
          <img
            className="triangleWhat"
            src={require("./assets/aboutus/triangle.svg").default}
            alt={""}
          />
          <img
            className="dotWhat"
            src={require("./assets/aboutus/dot.svg").default}
            alt={""}
          />
          <img
            className="ellipseWhat"
            src={require("./assets/aboutus/ellipse.svg").default}
            alt={""}
          />
        </Row>
      </StyledAboutUs> */}
    </motion.div>
  );
};

export default AboutUs;

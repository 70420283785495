import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPProcessInfo() {
  return (
    <>
      <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
      <p>
        <i>
          <b>In Short:</b> We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </i>
      </p>
      <p>
        <b>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </b>
        <ul>
          <li>
            <b>To deliver and facilitate delivery of services to the user.</b>{" "}
            We may process your information to provide you with the requested
            service.
          </li>
          <li>
            <b>To respond to user inquiries/offer support to users.</b> We may
            process your information to respond to your inquiries and solve any
            potential issues you might have with the requested service.
          </li>
          <li>
            <b>To send administrative information to you.</b> We may process
            your information to send you details about our products and
            services, changes to our terms and policies, and other similar
            information.
          </li>
          <li>
            <b>To fulfill and manage your orders.</b> We may process your
            information to fulfill and manage your orders, payments, returns,
            and exchanges made through the Services.
          </li>

          <li>
            <b>To enable user-to-user communications.</b> We may process your
            information if you choose to use any of our offerings that allow for
            communication with another user.
          </li>
          <li>
            <b>To request feedback.</b> We may process your information when
            necessary to request feedback and to contact you about your use of
            our Services.
          </li>
          <li>
            <b>To send you marketing and promotional communications.</b> We may
            process the personal information you send to us for our marketing
            purposes, if this is in accordance with your marketing preferences.
            You can opt out of our marketing emails at any time. For more
            information, see
            <a href="#privacyrights">"WHAT ARE YOUR PRIVACY RIGHTS?"</a> below.
          </li>
          <li>
            <b>To deliver targeted advertising to you.</b> We may process your
            information to develop and display personalized content and
            advertising tailored to your interests, location, and more.
          </li>
          <li>
            <b>To post testimonials.</b> We post testimonials on our Services
            that may contain personal information.
          </li>
          <li>
            <b>To protect our Services.</b> We may process your information as
            part of our efforts to keep our Services safe and secure, including
            fraud monitoring and prevention.
          </li>
          <li>
            <b>
              To evaluate and improve our Services, products, marketing, and
              your experience.
            </b>{" "}
            We may process your information when we believe it is necessary to
            identify usage trends, determine the effectiveness of our
            promotional campaigns, and to evaluate and improve our Services,
            products, marketing, and your experience.
          </li>
          <li>
            <b>To identify usage trends.</b> We may process information about
            how you use our Services to better understand how they are being
            used so we can improve them.
          </li>
          <li>
            <b>
              To determine the effectiveness of our marketing and promotional
              campaigns.
            </b>{" "}
            We may process your information to better understand how to provide
            marketing and promotional campaigns that are most relevant to you.
          </li>
          <li>
            <b>To comply with our legal obligations.</b> We may process your
            information to comply with our legal obligations, respond to legal
            requests, and exercise, establish, or defend our legal rights.
          </li>
        </ul>
      </p>
    </>
  );
}

export default PPProcessInfo;

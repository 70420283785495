import React from "react";
import { Row, Col, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import FAQTable from "../../../component/faq/faq-table";
import languageVar from "../../../services/Localization/Language";
import { ServiceTypeEnum } from "../../../services/enum";

import "./faq-services.scss";

function FAQServices({ type }) {
  function renderFAQTable() {
    switch (type) {
      case ServiceTypeEnum.WEB_DEV:
        return languageVar.web_faq_items.map((item, index) => (
          <FAQTable
            key={index + 1}
            title={item.header}
            text={item.text}
          ></FAQTable>
        ));
      case ServiceTypeEnum.MOBILE_DEV:
        return (
          <>
            {languageVar.mob_faq_items.map((item, index) => (
              <FAQTable
                key={index + 1}
                title={item.header}
                text={item.text}
              ></FAQTable>
            ))}
          </>
        );
      case ServiceTypeEnum.GRAPH_DESIGN:
        return (
          <>
            {languageVar.design_faq_items.map((item, index) => (
              <FAQTable
                key={index + 1}
                title={item.header}
                text={item.text}
              ></FAQTable>
            ))}
          </>
        );
      case ServiceTypeEnum.CONSULT:
        return (
          <>
            {languageVar.consult_faq_items.map((item, index) => (
              <FAQTable
                key={index + 1}
                title={item.header}
                text={item.text}
              ></FAQTable>
            ))}
          </>
        );
    }
  }
  return (
    <>
      <Row className="faq faq-service-detail">
        <Col md={12} xs={24}>
          <h1>FAQ</h1>
          <p>Frequently Asked Questions.</p>
          <p className="faq-desc">{languageVar.faq.desc}</p>
          {renderFAQTable()}
        </Col>
        <Col className="faq-service-detail-cta" md={12} xs={24}>
          <h3 style={{ marginBottom: "0" }}>{languageVar.faq.service_desc}</h3>
          <Button
            className="button-home"
            type="primary"
            onClick={() => (window.location.href = "/faq")}
          >
            {languageVar.faq.cta}
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
    </>
  );
}
export default FAQServices;

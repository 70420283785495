import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPKeepSafeInfo() {
  return (
    <>
      <h3>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
      <p>
        <i>
          <b>In Short:</b> We aim to protect your personal information through a
          system of organizational and technical security measures.
        </i>
      </p>
      <p>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>
    </>
  );
}
export default PPKeepSafeInfo;

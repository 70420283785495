import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPRightsInfo() {
  return (
    <>
      <h3>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
      <p>
        <i>
          <b>In Short:</b> You may review, change, or terminate your account at
          any time. <br />
        </i>
      </p>
      <p>
        <b>
          <u>Withdrawing your consent:</u>
        </b>{" "}
        If we are relying on your consent to process your personal information,
        which may be express and/or implied consent depending on the applicable
        law, you have the right to withdraw your consent at any time. You can
        withdraw your consent at any time by contacting us by using the contact
        details provided in the section "
        <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.{" "}
        <br />
        <br />
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>
      <p>
        <b>
          <u>Opting out of marketing and promotional communications:</u>
        </b>{" "}
        You can unsubscribe from our marketing and promotional communications at
        any time by clicking on the unsubscribe link in the emails that we send,
        or by contacting us using the details provided in the section "
        <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
        You will then be removed from the marketing lists. However, we may still
        communicate with you — for example, to send you service-related messages
        that are necessary for the administration and use of your account, to
        respond to service requests, or for other non-marketing purposes.
      </p>
      <p>
        <b>
          <u>Cookies and similar technologies:</u>
        </b>{" "}
        Most Web browsers are set to accept cookies by default. If you prefer,
        you can usually choose to set your browser to remove cookies and to
        reject cookies. If you choose to remove cookies or reject cookies, this
        could affect certain features or services of our Services. To opt out of
        interest-based advertising by advertisers on our Services visit
        <a href="http://www.aboutads.info/choices/">
          http://www.aboutads.info/choices/
        </a>
        .
        <br />
        <br />
        If you have questions or comments about your privacy rights, you may
        email us at wecare@cs.lumiiumtech.com.
      </p>
    </>
  );
}
export default PPRightsInfo;

import React from "react";
import { Collapse } from "antd";

import "./faq-table.scss";

const { Panel } = Collapse;

function FAQTable({ key, title = "", text = "" }) {
  function createMarkup() {
    return { __html: text };
  }
  return (
    <>
      {
        <div className="faq-table">
          <Collapse expandIconPosition="end" defaultActiveKey={[key]} ghost>
            <Panel header={title} key={key}>
              <div
                className="text"
                dangerouslySetInnerHTML={createMarkup()}
              ></div>
            </Panel>
          </Collapse>
        </div>
      }
    </>
  );
}
export default FAQTable;

import React, { useState, useEffect, useRef } from "react";
import languageVar from "./services/Localization/Language";
import { Popover, Menu, Dropdown } from "antd";
import {
  MenuOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  GlobalOutlined,
  LaptopOutlined,
  MobileOutlined,
  EditOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { motion, useScroll } from "framer-motion";
import { useCookies } from "react-cookie";

import "./Navbar.scss";

function Navbar({ setGlobalLanguage }) {
  /*Handle Loading*/
  const [langOpen, setlangOpen] = useState(false);
  const [serviceOpen, setServiceOpen] = useState(false);
  const [language, setLanguage] = useState("en");
  const [cookies, setCookie] = useCookies(["language"]);
  const firstRender = useRef(true);
  const hideLang = () => {
    setlangOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setlangOpen(newOpen);
  };

  const handleServiceOpenChange = (newOpen) => {
    setServiceOpen(newOpen);
  };

  const handleLangChange = (value) => {
    hideLang();
    setGlobalLanguage(value);
    setLanguage(value);
    languageVar.setLanguage(value);
  };

  /*Mobile Menu Item*/
  const items = [
    {
      label: <a href="/#about-us">{languageVar.about_us}</a>,
      key: "0",
    },
    {
      label: <a href="/#services">{languageVar.services}</a>,
      key: "1",
    },
    {
      label: (
        <a id="gtm-contact-us" href="/#contact-us">
          {languageVar.contact_us}
        </a>
      ),
      key: "2",
    },
    {
      label: <a href="https://lumiiumtech.com/blog/">Blog</a>,
      key: "3",
    },
    {
      label: (
        <a>
          <GlobalOutlined style={{ marginRight: "5px" }} />
          {language === "en" ? "Language" : "Bahasa"}
        </a>
      ),
      key: "3",
      children: [
        {
          label: (
            <span
              onClick={() => handleLangChange("en")}
              style={{ fontWeight: language == "en" && "bold" }}>
              English
            </span>
          ),
          key: "4",
        },
        {
          label: (
            <span
              onClick={() => handleLangChange("id")}
              style={{ fontWeight: language == "id" && "bold" }}>
              Indonesia
            </span>
          ),
          key: "5",
        },
      ],
    },
  ];

  function changeActivateMenu() {
    /*
      Handle changing navbar selected
      reference: https://stackoverflow.com/questions/69940934/change-the-active-list-when-scrolling-navbar
    */
    let section = document.querySelectorAll("section");
    let lists = document.querySelectorAll(".ant-menu-item");

    function activeLink(li, isServiceMenu = false) {
      lists.forEach((item) => item.classList.remove("ant-menu-item-selected"));
      const serviceTarget =
        document.querySelector(`[href='/#services']`).parentElement
          .parentElement;
      /* Service menu is special case (since it has child), so need to handle it manually*/
      if (isServiceMenu) {
        //add
        serviceTarget.classList.add("ant-menu-item-selected");
      } else {
        //remove
        serviceTarget.classList.remove("ant-menu-item-selected");
      }

      li.classList.add("ant-menu-item-selected");
    }
    lists.forEach((item) =>
      item.addEventListener("click", function () {
        activeLink(this);
      })
    );

    window.onscroll = () => {
      section.forEach((sec) => {
        let top = window.scrollY;
        let offset = sec.offsetTop;
        let height = sec.offsetHeight;
        let id = sec.getAttribute("id");
        if (top !== 0 && top >= offset / 1.1 && top < offset / 1.1 + height) {
          try {
            const target = document.querySelector(`[href='/#${id}']`)
              .parentElement.parentElement;
            if (target) {
              if (id == "services") {
                activeLink(target.parentElement, true);
              } else {
                activeLink(target);
              }
            }
          } catch {}
        } else if (top === 0) {
          let lists = document.querySelectorAll(".ant-menu-item");

          lists.forEach((item) => {
            item.classList.remove("ant-menu-item-selected");
          });
        }
      });
    };
  }

  const handleReload = () => {
    if (window.location.pathname == "/") {
      // Handle splash screen
      localStorage.removeItem("visited");
    }
    if (window.location.hash === "") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      /* Remove query params */
      let findQueryParams = "?";

      const indexOfFirst = window.location.hash
        .replace("#", "")
        .indexOf(findQueryParams);
      console.log(indexOfFirst);
      if (indexOfFirst >= 0) {
        document
          .getElementById(
            window.location.hash.replace("#", "").slice(0, indexOfFirst)
          )
          .scrollIntoView({
            behavior: "smooth",
          });
      } else {
        document
          .getElementById(window.location.hash.replace("#", ""))
          .scrollIntoView({
            behavior: "smooth",
          });
      }
    }
  };

  useEffect(() => {
    const onScroll = () => changeActivateMenu();
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    setTimeout(handleReload, 500);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (language == "en") {
      document.title =
        "LumiiumTech | Software House Creating Android, iOS, & Website App";
      document.getElementsByTagName("META")[3].content =
        "LumiiumTech is a professional IT company that provides the best android, iOS, website and any other digital solution development services in Indonesia.";
    } else {
      document.title =
        "LumiiumTech | Jasa Pembuatan Aplikasi Android, iOS & Website";
      document.getElementsByTagName("META")[3].content =
        "LumiiumTech adalah perusahaan IT profesional yang menyediakan jasa pembuatan aplikasi android, iOS, website, dan solusi digital terbaik di Indonesia.";
    }
    // setSearchParams({ lang: language }); Disable for now, causing routes error
  }, [language]);

  const { scrollYProgress } = useScroll();

  // Handle Cookie Language
  const changeCookie = () => {
    let expires = new Date();
    expires.setTime(expires.getTime() + 59 * 60 * 1000);
    setCookie("language", language, { path: "/", expires });
  };

  useEffect(() => {
    if (firstRender.current == true && cookies.language != undefined) {
      languageVar.setLanguage(cookies.language);
      handleLangChange(cookies.language);
      firstRender.current = false;
    }
    changeCookie();
  }, [language]);

  return (
    <nav className="menuBar">
      <div className="logoDesktop">
        <a
          href="/"
          style={{
            paddingTop: "30px",
          }}>
          <img
            src={require("./assets/logo/logo_horizontal.png")}
            style={{
              width: "120px",
              marginLeft: "25px",
            }}
          />
        </a>
      </div>
      <div className="logoMobile">
        <a href="/">
          <img
            src={require("./assets/logo/logo_only_small.png")}
            style={{ height: "50px", position: "absolute" }}
          />
        </a>
      </div>
      <div className="menuCon">
        <div className="language-popover">
          <Popover
            content={
              <>
                <div
                  className="listPopover"
                  onClick={() => handleLangChange("en")}>
                  <img
                    src={require("./assets/english.svg").default}
                    style={{ height: "18.4px" }}
                  />
                  <span className={"language"} style={{ marginLeft: "5px" }}>
                    English
                  </span>
                </div>
                <div
                  className="listPopover"
                  style={{ marginTop: "5px" }}
                  onClick={() => handleLangChange("id")}>
                  <img
                    src={require("./assets/indonesia.svg").default}
                    style={{ height: "18.4px" }}
                  />
                  <span className={"language"} style={{ marginLeft: "5px" }}>
                    Indonesia
                  </span>
                </div>
              </>
            }
            title=""
            trigger="click"
            placement="bottomLeft"
            open={langOpen}
            onOpenChange={handleOpenChange}>
            <img
              src={
                language === "en"
                  ? require("./assets/english.svg").default
                  : require("./assets/indonesia.svg").default
              }
              style={{ height: "18.4px" }}
            />

            <span className="down-arrow">
              {langOpen === false ? <CaretDownOutlined /> : <CaretUpOutlined />}
            </span>
          </Popover>
        </div>

        <div className="rightMenu">
          <Menu mode="horizontal">
            <Menu.Item key="aboutus">
              <a href="/#about-us">{languageVar.about_us}</a>
            </Menu.Item>
            <Menu.Item key="services" className="menu-services">
              <Popover
                content={
                  <div>
                    <div
                      className="listPopover"
                      onClick={() => (window.location.href = "/#services")}>
                      <span
                        className={"language"}
                        onClick={() => (window.location.href = "/#services")}
                        style={{ marginLeft: "5px" }}>
                        {languageVar.services}
                      </span>
                    </div>
                    <div
                      className="listPopover"
                      onClick={() =>
                        (window.location.href = "/website-service")
                      }>
                      <span
                        className={"language"}
                        style={{ marginLeft: "5px", marginTop: "10px" }}>
                        &ensp;
                        <LaptopOutlined /> {languageVar.website_service_title}
                      </span>
                    </div>
                    <div
                      className="listPopover"
                      onClick={() =>
                        (window.location.href = "/mobile-service")
                      }>
                      <span
                        className={"language"}
                        style={{ marginLeft: "5px", marginTop: "5px" }}>
                        &ensp;
                        <MobileOutlined /> {languageVar.mobile_service_title}
                      </span>
                    </div>
                    <div
                      className="listPopover"
                      onClick={() =>
                        (window.location.href = "/design-service")
                      }>
                      <span
                        className={"language"}
                        style={{ marginLeft: "5px", marginTop: "5px" }}>
                        &ensp;
                        <EditOutlined /> {languageVar.design_service_title}
                      </span>
                    </div>
                    <div
                      className="listPopover"
                      onClick={() =>
                        (window.location.href = "/consultant-service")
                      }>
                      <span
                        className={"language"}
                        style={{ marginLeft: "5px", marginTop: "5px" }}>
                        &ensp;
                        <MessageOutlined />{" "}
                        {languageVar.consultant_service_title}
                      </span>
                    </div>
                  </div>
                }
                title=""
                trigger="click"
                placement="bottomRight"
                open={serviceOpen}
                onOpenChange={handleServiceOpenChange}>
                <span className="down-arrow">
                  <a href="/#services">{languageVar.services}</a>
                  {serviceOpen === false ? (
                    <CaretDownOutlined />
                  ) : (
                    <CaretUpOutlined />
                  )}
                </span>
              </Popover>
            </Menu.Item>
            <Menu.Item key="contactus">
              <a id="gtm-contact-us" href="/#contact-us">
                {languageVar.contact_us}
              </a>
            </Menu.Item>
            <Menu.Item key="blog">
              <a href="https://lumiiumtech.com/blog/">Blog</a>
            </Menu.Item>
          </Menu>
        </div>

        <Dropdown
          className="barsMenu"
          menu={{
            items,
          }}
          placement="bottomRight"
          trigger={["click"]}>
          <span onClick={(e) => e.preventDefault()}>
            <MenuOutlined style={{ color: "var(--white-color)" }} />
          </span>
        </Dropdown>
      </div>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
    </nav>
  );
}

export default Navbar;

import React from "react";
import { Button, Row, Col, Card } from "antd";
import strings from "./services/Localization/Language";
import { motion } from "framer-motion";
import SoftwareDevelopmentTool from "./SoftwareDevelopmentTool";
import {
  ArrowRightOutlined,
  TeamOutlined,
  BulbOutlined,
  BarChartOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import AnotherServices from "./AnotherServices";
import FAQServices from "./component/faq/faq-services/faq-services";

import { ConsultantAdvantages, ServiceTypeEnum } from "./services/enum";

const ConsultantService = () => {
  return (
    <div
      className="detail-service-background"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8 }}
    >
      <Row className="detail-service">
        <Col xl={16} md={24}>
          <motion.div
            initial={{ opacity: 0, translateX: 100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ fallback: true }}
          >
            <h1 className="home-title">{strings.consultant_service_title}</h1>
            <div className="service-img-mobile">
              <img
                src={require("./assets/service/consult.png")}
                className="detail-consultant-service"
                alt="design"
              />
            </div>
            <p>{strings.consultant_service_desc}</p>
            <p style={{ marginTop: "15px" }}>
              {strings.consultant_service_subdesc}
            </p>
            <p className="service-detail-message">
              {strings.service_detail_message}
            </p>
            <Row className="list-detail-service">
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Digital transformation consulting</span>
              </Col>
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>IT strategy consulting</span>
              </Col>
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>IT operations consulting</span>
              </Col>
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Technology consulting</span>
              </Col>
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Application modernization</span>
              </Col>
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Application support</span>
              </Col>
              <Col span={24}>
                <Button
                  id="gtm-contact-us"
                  className="button-home"
                  type="primary"
                  onClick={() => (window.location.href = "/#contact-us")}
                >
                  {strings.service_detail_quote}
                  <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </motion.div>
        </Col>
        <Col xl={8} md={24} style={{ textAlign: "center" }}>
          <motion.div
            initial={{ opacity: 0, translateX: 100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ fallback: true }}
          >
            <div>
              <img
                src={require("./assets/service/consult.png")}
                className="service-img"
                alt="design"
              />
            </div>
          </motion.div>
        </Col>
      </Row>
      <Row style={{ marginTop: "3%" }}>
        <Col span={24}>
          <h3 style={{ color: "var(--primary-color)", textAlign: "center" }}>
            {strings.service_benefit}
          </h3>
        </Col>
      </Row>
      <Row
        className="detail-service-feature"
        style={{ justifyContent: "center" }}
        gutter={[48, 24]}
      >
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <TeamOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{ConsultantAdvantages[0]}</span>
          </Card>
        </Col>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <BulbOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{ConsultantAdvantages[1]}</span>
          </Card>
        </Col>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <BarChartOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{ConsultantAdvantages[2]}</span>
          </Card>
        </Col>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <CheckCircleOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{ConsultantAdvantages[3]}</span>
          </Card>
        </Col>
      </Row>
      <SoftwareDevelopmentTool type={ServiceTypeEnum.CONSULT} />
      <FAQServices type={ServiceTypeEnum.CONSULT}></FAQServices>
      <AnotherServices currentService={ServiceTypeEnum.CONSULT} />
    </div>
  );
};

export default ConsultantService;

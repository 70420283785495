let DesignServiceText = {
  en: {
    design_service_title: "Graphic Design",
    design_service_desc:
      "If you need creative, unique, and attractive design services, we can help you. We can create engaging information using a mix of design, photography, motion graphics, and many more to reach your audience in an impactful manner.",
    design_service_subdesc:
      "We are a group of talented and professional graphic designers. We create designs that suit your needs with sharp imagination and creativity.",
  },
  id: {
    design_service_title: "Desain Grafis",
    design_service_desc:
      "Kami dapat membantu Anda dengan berbagai desain grafis. Kami dapat menyampaikan informasi melalui media visual seperti logo, ilustrasi, dan lainnya untuk menjangkau audiens Anda dengan dampak yang luar biasa.",
    design_service_subdesc:
      "Kami memiliki desainer grafis yang berbakat dan profesional. Kami dapat membuat desain yang sesuai untuk kebutuhan Anda dengan imajinasi dan kreativitas yang tinggi.",
  },
};
var DesignServicefaqItems = {
  en: {
    design_faq_items: [
      {
        header:
          "How much does it cost to make designs such as logos, posters, social media kits, 3d, and so on?",
        text: `The cost of making graphic designs varies depending on the type and level of difficulty. We have estimated the price for you, please click here.\n\nIf none of these details suit your needs, consult with us for an estimate of the costs required.`,
      },
      {
        header: "What if I am not satisfied with the design provided?",
        text: `Of course we will try our best to provide the best design for you. But if you are still not satisfied, we provide revision service for you. The number of revisions depends on the package you choose.`,
      },
    ],
  },
  id: {
    design_faq_items: [
      {
        header:
          "Berapa biaya pembuatan desain seperti logo, poster, socmed kit, 3d, dan lain-lain?",
        text: `Biaya pembuatan desain grafis bervariasi tergantung jenis dan tingkat kesulitannya. Kami sudah membuat estimasi harganya untuk Anda, silahkan klik disini.\n\nJika dalam rincian tersebut dirasa tidak ada yang sesuai dengan kebutuhan Anda, konsultasikan dengan kami untuk estimasi biaya yang dibutuhkan.`,
      },
      {
        header: "Bagaimana kalau saya tidak puas dengan desain yang diberikan?",
        text: `Tentunya kami akan berusaha semaksimal mungkin untuk memberikan desain yang terbaik untuk Anda. Tetapi jika Anda masih belum puas, kami menyediakan layanan untuk revisi untuk Anda. Jumlah revisi tergantung dengan paket yang Anda pilih.`,
      },
    ],
  },
};

export default { DesignServiceText, DesignServicefaqItems };

let websiteServiceText = {
  en: {
    website_service_title: "Website Development",
    website_service_desc:
      "We offer high-quality website development including simple websites and web-based applications. We design, build, and maintain the web tailored for your needs. The benefits are increasing credibility, brand awareness, sales, and facilitating various activities for you or your company.",
    website_service_subdesc:
      "We have professional designers and developers for creating interactive and innovative websites that are ready to handle multiple users.",
  },
  id: {
    website_service_title: "Website Development",
    website_service_desc:
      "Kami menawarkan jasa pembuatan website berkualitas tinggi mencakup website sederhana hingga aplikasi berbasis web mulai dari desain, pembuatan, hingga pemeliharaan. Dengan ini kamu bisa meningkatkan kredibilitas, brand awareness, penjualan, hingga mempermudah berbagai aktivitas kamu atau perusahaanmu.",
    website_service_subdesc:
      "Kami memiliki desainer dan pengembang yang profesional untuk menciptakan website yang interaktif dan inovatif yang siap untuk menangani banyak pengguna.",
  },
};

var websiteServicefaqItems = {
  en: {
    web_faq_items: [
      {
        header: "What types of websites can LumiiumTech develop?",
        text: `Websites that we can develop are : \n
        <ol>
          <li>Company Profile Website /Business</li>
          <li>Online Shop/e-commerce Website</li>
          <li>Media and News Website</li>
          <li>Government/Institution Official Website</li>
          <li>Educational Website</li>
          <li>Portfolio Website</li>
          <li>Non-Profit Websites</li>
          <li>Custom Websites</li>
          <li>Blog</li>
        </ol>`,
      },
      {
        header: "How much does it cost to create a website?",
        text: `The cost of making a website varies greatly, depending on the features and difficulty of the website. Click <a href="/website-service/#web-price">here</a> to see the packages that you can choose and their costs.\n\nIf you feel nothing suits your needs, consult with us for details on the costs required.`,
      },
      {
        header:
          "How can I monitor the website development process that I ordered?",
        text: `You can see whenever the development of the website you ordered via the URL that we will provide. We will also inform you directly when your website development has reached 25%, 50%, 75% and 100% progress.`,
      },
      {
        header:
          "Can LumiiumTech help add features to existing applications or websites?",
        text: `We can provide additional features or renovation of the finished website for websites made by LumiiumTech. For websites made by other developer, we need to check the website first to decide whether it can be done or not.`,
      },
      {
        header: "What needs to be prepared in website development?",
        text: `What you need to prepare is a description of the website, the purpose of the website, the domain name, for example: www.lumiiumtech.com (can be .id, .co.id, .net, etc.), logo, photo/image to be displayed, text or content, and others.\n\nDon't worry if you don't have logos, images, text, and so on yet. LumiiumTech will help you get the best.`,
      },
      {
        header:
          "Why should there be a website renewal fee for the following year?",
        text: `A website renewal fee annually is required to keep your domain available. If you do not renew when the expiration date has passed, your domain or website address will be removed.\n\nBut don't worry, we'll send you information if your website needs to be extended. Details of the renewal costs can be seen in each package.`,
      },
      {
        header:
          "Can LumiiumTech develop an online shop/e-Commerce website with payment features in it?",
        text: `Yes we can. We provide packages for creating online/e-commerce stores with payment features. Please see the packages we provide <a href="/website-service/#web-price">here</a>.`,
      },
    ],
  },
  id: {
    web_faq_items: [
      {
        header: "Apa saja jenis-jenis website yang bisa dibuat LumiiumTech?",
        text: `Website yang dapat kami buat adalah : \n
        <ol>
          <li>Website Company Profile/Bisnis</li>
          <li>Website Toko Online/e-Commerce</li>
          <li>Website Portal Media dan Berita</li>
          <li>Website Resmi Pemerintah/Institusi</li>
          <li>Website Pendidikan</li>
          <li>Website Portofolio</li>
          <li>Website Non-Profit</li>
          <li>Website Custom</li>
          <li>Blog</li>
        </ol>`,
      },
      {
        header: "Berapa biaya untuk pembuatan website?",
        text: `Biaya pembuatan website sangat bervariasi, tergantung dengan fitur-fitur dan tingkat kesulitan pembuatan website. Klik <a href="/website-service/#web-price">disini</a> untuk melihat paket-paket yang dapat Anda pilih beserta biayanya.\n\nJika dirasa tidak ada yang sesuai dengan kebutuhan Anda, konsultasikan dengan kami untuk rincian biaya yang dibutuhkan.`,
      },
      {
        header:
          "Bagaimana agar saya bisa memantau proses pengembangan website yang saya pesan?",
        text: `Anda dapat melihat kapanpun perkembangan website yang Anda pesan melalui URL yang akan kami berikan. Kami juga akan menginformasikan secara langsung kepada Anda ketika pengembangan website Anda sudah mencapai progress 25%, 50%, 75%, dan 100%.`,
      },
      {
        header:
          "Apakah LumiiumTech dapat membantu penambahan fitur terhadap aplikasi maupun website yang sudah jadi?",
        text: `Kami dapat melayani penambahan fitur atau renovasi website yang sudah jadi untuk website yang dibuat oleh LumiiumTech. Untuk website yang dibuat oleh pihak luar, kami perlu mengecek terlebih dahulu website tersebut untuk memutuskan apakah bisa atau tidak.`,
      },
      {
        header: "Apa yang perlu dipersiapkan dalam pengembangan website?",
        text: `Yang perlu Anda siapkan adalah gambaran website, tujuan pembuatan website, nama domain, contoh: www.lumiiumtech.com (bisa .id, .co.id, .net, dsb), logo, foto/gambar yang akan ditampilkan, teks atau konten, dan lain-lain.\n\nJangan khawatir jika Anda belum mempunyai logo, gambar, teks, dan sebagainya. LumiiumTech akan membantu mendapatkan yang terbaik untuk Anda.`,
      },
      {
        header:
          "Kenapa harus ada biaya perpanjangan website untuk tahun berikutnya?",
        text: `Biaya perpanjangan website setiap 1 tahun diperlukan untuk menjaga domain Anda tetap tersedia. Jika Anda tidak memperpanjang ketika sudah lewat batas waktu, maka domain atau alamat website Anda akan dihapus.\n\nTetapi jangan khawatir, kami akan mengirimkan informasi jika website Anda perlu diperpanjang. Untuk rincian biayanya dapat dilihat pada masing-masing paket.`,
      },
      {
        header:
          "Apakah LumiiumTech bisa membuat website toko online/e-Commerce dengan fitur pembayaran di dalamnya?",
        text: `Sangat bisa. Kami menyediakan paket untuk membuat toko online/e-Commerce dengan fitur pembayaran. Silahkan lihat paket yang kami sediakan disini.`,
      },
    ],
  },
};

export default { websiteServiceText, websiteServicefaqItems };

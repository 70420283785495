let contactUsText = {
  en: {
    contact_us_subtitle: "Have a business idea ?",
    contact_us_title: "Let's Work Together",
    contact_us_desc:
      "We’d love to hear from you! Send us a message using the form below and we'll be in contact shortly. You can also use the button on the right corner to chat with us!",

    // Field
    contact_field_name: "Name",
    contact_field_name_desc: "Your Name",
    contact_field_email: "Email",
    contact_field_email_desc: "Your Email",
    contact_field_company: "Company",
    contact_field_company_desc: "Company Name",
    contact_field_message: "How do you think can we help you?",
    contact_field_message_desc:
      "A brief description on how you think LumiiumTech could help",
    contact_field_service: "Type of Query",
    contact_field_service_desc: "Please select the query",
    contact_service_others: "Others",
    contact_button: "Submit",
    contact_warning: 'If this is for personal needs please fill in "Personal"',
    contact_name_warning: "Please input your username",
    contact_email_warning: "Please input your email",
    contact_company_warning: "Please input your company",
    contact_message_warning: "Please input your message",
    contact_service_warning: "Please input select type of query",
  },
  id: {
    contact_us_subtitle: "Mempunyai ide bisnis ?",
    contact_us_title: "Mari Kerja Bersama",
    contact_us_desc:
      "Kami senang mendengarkan Anda! Kirim kami pesan menggunakan formulir di bawah ini dan kami akan segera menghubungi Anda. Anda juga dapat menggunakan tombol di kanan bawah untuk mengobrol dengan kami!",

    // Field
    contact_field_name: "Nama",
    contact_field_name_desc: "Nama Anda",
    contact_field_email: "Email",
    contact_field_email_desc: "Email Anda",
    contact_field_company: "Perusahaan",
    contact_field_company_desc: "Nama Perusahaan",
    contact_field_message: "Bagaimana kami dapat membantu Anda?",
    contact_field_message_desc:
      "Deskripsi singkat tentang bagaimana menurut Anda LumiiumTech dapat membantu",
    contact_field_service: "Jenis Pertanyaan",
    contact_field_service_desc: "Pilih jenis pertanyaan",
    contact_service_others: "Lainnya",
    contact_button: "Kirim",
    contact_warning:
      'Jika untuk kebutuhan sendiri silahkan isi dengan "Personal"',
    contact_name_warning: "Tolong masukkan username anda",
    contact_email_warning: "Tolong masukkan email anda",
    contact_company_warning: "Tolong masukkan perusahaan anda",
    contact_message_warning: "Tolong masukkan pesan anda",
    contact_service_warning: "Tolong pilih jenis pertanyaan",
  },
};

export default contactUsText;

import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

import { FileTextOutlined } from "@ant-design/icons";

import "./PrivacyPolicy.scss";

// Import PP
import PPCollectInfo from "./privacy-policy-content/privacy-collect-info.js";
import PPProcessInfo from "./privacy-policy-content/process-info.js";
import PPShareInfo from "./privacy-policy-content/share-info.js";
import PPCookiesInfo from "./privacy-policy-content/cookies-info.js";
import PPKeepInfo from "./privacy-policy-content/keep-info.js";
import PPKeepSafeInfo from "./privacy-policy-content/keep-safe-info.js";
import PPMinorInfo from "./privacy-policy-content/minor-info.js";
import PPRightsInfo from "./privacy-policy-content/rights-info.js";
import PPNotTrackInfo from "./privacy-policy-content/not-track-info.js";
import PPUpdateInfo from "./privacy-policy-content/update-info.js";
import PPContactInfo from "./privacy-policy-content/contact-info.js";
import PPReviewInfo from "./privacy-policy-content/review-info.js";

function PrivacyPolicy() {
  return (
    <>
      <Row className="privacy-pol-row" gutter={(16, 16)}>
        <Col className="privacy-pol-title" span={24}>
          <div style={{ display: "block" }}>
            <h1>Privacy Policy</h1>
            <h4>Last updated January 04, 2023</h4>
          </div>

          <div className="privacy-pol-icon">
            <FileTextOutlined />
          </div>
        </Col>

        <Col className="privacy-notice" xs={24} md={12}>
          <p>
            This privacy notice for LumiiumTech ("<b>Company</b>," "<b>we</b>,"
            "<b>us</b>," or "<b>our</b>"), describes how and why we might
            collect, store, use, and/or share ("<b>process</b>") your
            information when you use our services ("<b>Services</b>"), such as
            when you:
            <ul>
              <li>
                Visit our website at{" "}
                <a href="https://www.lumiiumtech.com">
                  https://www.lumiiumtech.com
                </a>
                , or any website of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <b>Questions or concerns?</b> Reading this privacy notice will help
            you understand your privacy rights and choices. If you do not agree
            with our policies and practices, please do not use our Services. If
            you still have any questions or concerns, please contact us at
            wecare@cs.lumiiumtech.com.
          </p>
        </Col>
        <Col className="key-point" xs={24} md={12}>
          <h2>SUMMARY OF KEY POINTS</h2>
          <b>
            <i>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for. You can
              also click
              <a href="#toc"> here</a> to go directly to our table of contents.
            </i>
          </b>
          <p>
            <b>Do we process any sensitive personal information?</b> We do not
            process sensitive personal information.
          </p>
          <p>
            <b>Do we receive any information from third parties?</b> We may
            receive information from public databases, marketing partners,
            social media platforms, and other outside sources. Click
            <a href="#othersources"> here</a> to learn more.
          </p>
          <p>
            <b>How do we process your information?</b> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Click
            <a href="#infouse"> here</a> to learn more.
          </p>
          <p>
            <b>What are your rights?</b> Depending on where you are located
            geographically, the applicable privacy law may mean you have certain
            rights regarding your personal information. Click
            <a href="#privacyrights"> here</a> to learn more.
          </p>
          <p>
            <b>How do you exercise your rights?</b> The easiest way to exercise
            your rights is by filling out our data subject request form
            available
            <a href="https://app.termly.io/notify/db1adf2f-770c-4dfc-bc72-78962067d419">
              {" "}
              here
            </a>
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>
          Want to learn more about what LumiiumTech does with any information we
          collect? Click <a href="#toc"> here</a> to review the notice in full.
        </Col>
        <Col id="toc" className="toc" xs={24} md={12}>
          <h2>TABLE OF CONTENTS</h2>
          <ol>
            <li>
              <a href="#infocollect">WHAT INFORMATION DO WE COLLECT?</a>
            </li>
            <li>
              <a href="#infouse">HOW DO WE PROCESS YOUR INFORMATION?</a>
            </li>
            <li>
              <a href="#whoshare">
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </li>
            <li>
              <a href="#cookies">
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </li>
            <li>
              <a href="#inforetain">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
            </li>
            <li>
              <a href="#infosafe">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
            </li>
            <li>
              <a href="#infominors">DO WE COLLECT INFORMATION FROM MINORS?</a>
            </li>
            <li>
              <a href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </li>
            <li>
              <a href="#DNT">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
            </li>
            <li>
              <a href="#policyupdates">DO WE MAKE UPDATES TO THIS NOTICE?</a>
            </li>
            <li>
              <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
            </li>
            <li>
              <a href="#request">
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </a>
            </li>
          </ol>
        </Col>
        <Col className="pp-content" span={24}>
          <section id="infocollect">
            <PPCollectInfo></PPCollectInfo>
          </section>
          <section id="infouse">
            <PPProcessInfo></PPProcessInfo>
          </section>
          <section id="whoshare">
            <PPShareInfo></PPShareInfo>
          </section>
          <section id="cookies">
            <PPCookiesInfo></PPCookiesInfo>
          </section>
          <section id="inforetain">
            <PPKeepInfo></PPKeepInfo>
          </section>
          <section id="infosafe">
            <PPKeepSafeInfo></PPKeepSafeInfo>
          </section>
          <section id="infominors">
            <PPMinorInfo></PPMinorInfo>
          </section>
          <section id="privacyrights">
            <PPRightsInfo></PPRightsInfo>
          </section>
          <section id="DNT">
            <PPNotTrackInfo></PPNotTrackInfo>
          </section>
          <section id="policyupdates">
            <PPUpdateInfo></PPUpdateInfo>
          </section>
          <section id="contact">
            <PPContactInfo></PPContactInfo>
          </section>
          <section id="request">
            <PPReviewInfo></PPReviewInfo>
          </section>
        </Col>
      </Row>
    </>
  );
}

export default PrivacyPolicy;

import React, { useState } from "react";
import { Row, Col, Card, Tag, Button, Table, Space } from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { WebPackageEnum, ServiceTypeEnum } from "../../services/enum";
import languageVar from "../../services/Localization/Language";
import "./pricing-card.scss";

function PricingCards({ type }) {
  const [detailFeature, setdetailFeature] = useState(true);

  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  function numberFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  function toogleOpenFeature() {
    setdetailFeature(!detailFeature);
    const hiddenBenefits = document.querySelectorAll(
      ".price-benefit.hidden-benefit"
    );

    hiddenBenefits.forEach((benefit) => {
      if (detailFeature == true) {
        benefit.classList.remove("hide");
      } else {
        benefit.classList.add("hide");
      }
    });
  }

  function showWebBenefitItems() {
    var webPackageEnumItems = Object.values(WebPackageEnum);
    var webPackageEnumKey = Object.keys(WebPackageEnum);
    Object.keys(ServiceTypeEnum);
    return webPackageEnumItems.map((item, index) => (
      <Col lg={6} md={12} xs={24} className="pricing-card-col">
        <h3 style={{ marginBottom: "0" }}>
          {webPackageEnumKey[index][0].toUpperCase() +
            webPackageEnumKey[index].substring(1)}
        </h3>

        {item == WebPackageEnum.basic && (
          <p style={{ marginTop: "0", height: "47px" }}>
            {languageVar.pricing_web.basic_package_desc}
          </p>
        )}
        {item == WebPackageEnum.business && (
          <p style={{ marginTop: "0", height: "47px" }}>
            {languageVar.pricing_web.business_package_desc}
          </p>
        )}
        {item == WebPackageEnum.professional && (
          <p style={{ marginTop: "0", height: "47px" }}>
            {languageVar.pricing_web.pro_package_desc}
          </p>
        )}
        {item == WebPackageEnum.corporate && (
          <p style={{ marginTop: "0", height: "47px" }}>
            {languageVar.pricing_web.corp_package_desc}
          </p>
        )}

        <Card
          className={`pricing-card  ${item.is_recomended && "best-value-card"}`}
        >
          {item.is_recomended && (
            <Tag color="#ef4d4d" style={{ float: "right" }}>
              Best Value!
            </Tag>
          )}

          <div className="price-strike">
            <span className="currency">Rp</span>
            <span>{currencyFormat(item.normal_price)}</span>
          </div>
          <div className="price">
            <span className="currency">Rp</span>
            <span>{currencyFormat(item.discount_price)}</span>
            <div className="tax-info">
              {languageVar.pricing_web_card.price_desc}
            </div>
          </div>
          <div>
            <Button
              className="pricing-contact"
              type="primary"
              style={{ width: "100%" }}
              onClick={() =>
                (window.location.href = `/#contact-us${getRoutingPath(
                  webPackageEnumKey[index]
                )}`)
              }
            >
              {languageVar.pricing_web_card.order_now}
            </Button>
          </div>
          <div className="price-extend">
            <h4>{languageVar.pricing_web_card.extend_desc}</h4>
            <span className="currency">Rp</span>
            <span>
              {currencyFormat(item.extend_price)} /{" "}
              {languageVar.pricing_web_card.year}
            </span>
            <span className="tax-info">
              &nbsp;{languageVar.pricing_web_card.price_desc}
            </span>
          </div>
          <div className="price-benefit">
            <h4>{languageVar.pricing_web.best_feature}</h4>
            <div className="item">
              <CheckCircleFilled /> <b>{languageVar.pricing_web_card.free}</b>{" "}
              domain <span className="item-value">{item.domain}</span>
            </div>
            <div className="item">
              <CheckCircleFilled /> {languageVar.pricing_web_card.visitor}{" "}
              <b>{numberFormat(item.visitor)}</b>
            </div>
            {item.is_SSL && (
              <div className="item">
                <CheckCircleFilled /> <b>{languageVar.pricing_web_card.free}</b>{" "}
                {languageVar.pricing_web_card.certificate} SSL/HTTPS
              </div>
            )}

            <div className="item">
              <CheckCircleFilled /> {languageVar.pricing_web_card.total_page}{" "}
              <b>{item.total_page}</b>
            </div>
            <div className="item">
              <CheckCircleFilled /> Email professional <b>{item.total_email}</b>
            </div>
            <div className="item">
              <CheckCircleFilled /> <b>{languageVar.pricing_web_card.free}</b>{" "}
              maintenance {item.total_maintenance}x/
              {languageVar.pricing_web_card.month}
            </div>
            <div className="item">
              <CheckCircleFilled /> SEO <b>{item.SEO}</b>{" "}
            </div>
          </div>

          {/* Detail benefit */}
          <div className="price-benefit hide hidden-benefit">
            <h4>{languageVar.pricing_web.other_feature}</h4>

            <div className="item">
              <CheckCircleFilled /> Design <b>{item.design_type}</b>
            </div>
            <div className="item">
              <CheckCircleFilled />{" "}
              {languageVar.pricing_web_card.total_revision}{" "}
              <b>{item.total_revision}x</b>
            </div>
            {item.set_up_wp && (
              <div className="item">
                <CheckCircleFilled /> <b>{languageVar.pricing_web_card.free}</b>{" "}
                set up Wordpress
              </div>
            )}
            {item.total_article > 0 && (
              <div className="item">
                <CheckCircleFilled /> <b>{languageVar.pricing_web_card.free}</b>{" "}
                {languageVar.pricing_web_card.article}{" "}
                <b>{item.total_article}</b>
              </div>
            )}
            {item.is_multi_lang && (
              <div className="item">
                <CheckCircleFilled />
                {languageVar.pricing_web_card.dual_lang}
              </div>
            )}

            {item.is_google_analytic && (
              <div className="item">
                <CheckCircleFilled /> <b>{languageVar.pricing_web_card.free}</b>{" "}
                setup Google Analytic
              </div>
            )}

            {item.chat && (
              <div className="item">
                <CheckCircleFilled /> {languageVar.pricing_web_card.chat}{" "}
                <b>{item.chat}</b>
              </div>
            )}

            {item.is_anti_copy && (
              <div className="item">
                <CheckCircleFilled /> <b>{languageVar.pricing_web_card.free}</b>{" "}
                anticopy protection
              </div>
            )}
          </div>
          <div className="pricing-detail-div">
            <hr />
            <a
              id="gtm-contact-us"
              className="pricing-detail"
              type="primary"
              onClick={() => toogleOpenFeature()}
            >
              {detailFeature
                ? languageVar.pricing_web.see_more
                : languageVar.pricing_web.see_less}
            </a>
          </div>
        </Card>
      </Col>
    ));
  }

  function getRoutingPath(type) {
    if (type == "basic") {
      type = ServiceTypeEnum.WEB_DEV_BASIC;
    } else if (type == "professional") {
      type = ServiceTypeEnum.WEB_DEV_PRO;
    } else if (type == "business") {
      type = ServiceTypeEnum.WEB_DEV_BUSINESS;
    } else if (type == "corporate") {
      type = ServiceTypeEnum.WEB_DEV_CORP;
    }

    switch (type) {
      case ServiceTypeEnum.WEB_DEV_BASIC:
        return `?package=${ServiceTypeEnum.WEB_DEV_BASIC}`;
      case ServiceTypeEnum.WEB_DEV_BUSINESS:
        return `?package=${ServiceTypeEnum.WEB_DEV_BUSINESS}`;
      case ServiceTypeEnum.WEB_DEV_CORP:
        return `?package=${ServiceTypeEnum.WEB_DEV_CORP}`;
      case ServiceTypeEnum.WEB_DEV_PRO:
        return `?package=${ServiceTypeEnum.WEB_DEV_PRO}`;

      case ServiceTypeEnum.MOBILE_DEV_HEALTH:
        return `?package=${ServiceTypeEnum.MOBILE_DEV_HEALTH}`;
      case ServiceTypeEnum.MOBILE_DEV_CASHIER:
        return `?package=${ServiceTypeEnum.MOBILE_DEV_CASHIER}`;
      case ServiceTypeEnum.MOBILE_DEV_SHOP:
        return `?package=${ServiceTypeEnum.MOBILE_DEV_SHOP}`;
      case ServiceTypeEnum.MOBILE_DEV_WEB_VIEW:
        return `?package=${ServiceTypeEnum.MOBILE_DEV_WEB_VIEW}`;
    }
  }

  const columns = [
    {
      className: "price-table-col",
      title: `${languageVar.pricing_mobile.header_type}`,
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (text) => <b>{text}</b>,
    },
    {
      title: `${languageVar.pricing_mobile.header_price}`,
      children: [
        {
          title: "Android",
          dataIndex: "android",
          key: "android",
          align: "center",
          width: "18%",

          render: (text) => (
            <>
              <span className="currency">Rp </span>
              <span>{currencyFormat(text)}</span>
            </>
          ),
        },
        {
          title: "iOS",
          dataIndex: "ios",
          key: "ios",
          align: "center",
          width: "18%",

          render: (text) => (
            <>
              <span className="currency">Rp </span>
              <span>{currencyFormat(text)}</span>
            </>
          ),
        },
        {
          title: `${languageVar.pricing_mobile.header_cross}`,
          dataIndex: "cross",
          key: "cross",
          align: "center",
          width: "18%",

          render: (text) => (
            <>
              <span className="currency">Rp </span>
              <span>{currencyFormat(text)}</span>
            </>
          ),
        },
      ],
    },
    {
      title: "",
      dataIndex: "contact",
      key: "contact",
      width: "20%",
      render: (text) => (
        <Button
          className="pricing-contact"
          type="primary"
          style={{ width: "100%" }}
          onClick={() =>
            (window.location.href = `/#contact-us${getRoutingPath(text)}`)
          }
        >
          {languageVar.pricing_web_card.order_now}
        </Button>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      name: "Web-view",
      android: 1500000,
      ios: 2500000,
      cross: 3500000,
      contact: `${ServiceTypeEnum.MOBILE_DEV_WEB_VIEW}`,
    },
    {
      key: "2",
      name: `${languageVar.pricing_mobile.eshope}`,
      android: 5000000,
      ios: 7000000,
      cross: 9000000,
      contact: `${ServiceTypeEnum.MOBILE_DEV_SHOP}`,
    },
    {
      key: "3",
      name: `${languageVar.pricing_mobile.health}`,
      android: 10000000,
      ios: 12000000,
      cross: 15000000,
      contact: `${ServiceTypeEnum.MOBILE_DEV_HEALTH}`,
    },
    {
      key: "4",
      name: "Cashier",
      android: 15000000,
      ios: 17000000,
      cross: 20000000,
      contact: `${ServiceTypeEnum.MOBILE_DEV_CASHIER}`,
    },
  ];

  return (
    <>
      <Row className="pricing-row" gutter={[16, 16]}>
        <Col span={24} style={{ textAlign: "center" }}>
          <h1>{languageVar.pricing_web.title}</h1>
          <p style={{ maxWidth: "inherit" }}>{languageVar.pricing_web.desc}</p>
        </Col>

        {type == ServiceTypeEnum.WEB_DEV && showWebBenefitItems()}

        {type == ServiceTypeEnum.MOBILE_DEV && (
          <Col span={24}>
            <Table
              bordered={true}
              pagination={false}
              columns={columns}
              dataSource={data}
              scroll={{
                x: 500,
              }}
            />
          </Col>
        )}
      </Row>
      <div className="detail-service-contact">
        <div style={{ display: "block", textAlign: "center" }}>
          <img
            className="img-ds-plan"
            src={require("../../assets/service/contact-us.png")}
            alt={""}
          />
          &nbsp;
          <h3 style={{ color: "white" }}>
            {languageVar.pricing_web.contact_us_plan}
          </h3>
          <Button
            type="primary"
            className="btn-ds-contact"
            onClick={() => (window.location.href = "/#contact-us")}
          >
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>
              Contact Us Here
            </span>
          </Button>
        </div>
      </div>
    </>
  );
}
export default PricingCards;

import "./App.css";
import React, { useState } from "react";
import languageVar from "./services/Localization/Language";
import { StyledDashboard } from "./Styles";
import { FloatButton, Spin } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";

// Custom Component
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import Home from "./Home";
import CompanyFooter from "./CompanyFooter";
import Services from "./Services";
import MobileService from "./MobileService";
import WebsiteService from "./WebsiteService";
import DesignService from "./DesignService";
import ConsultantService from "./ConsultantService";
import NotFound from "./NotFoundPage";
import Splash from "./Splash";
import Navbar from "./Navbar";
import FAQ from "./FAQ";
import PrivacyPolicy from "./component/privacy-policy/PrivacyPolicy";

function App() {
  /*Handle Language*/
  const [language, setLanguage] = useState("en");
  // const [searchParams, setSearchParams] = useSearchParams(); Disable for now, causing routes error

  const [loading, setLoading] = useState(false);
  const hideLoading = () => {
    setLoading(false);
  };

  const setGlobalLanguage = (childdata) => {
    setLoading(true);
    setTimeout(hideLoading, 500);
    setLanguage(childdata);
  };

  const spinningState = (childdata) => {
    setLoading(true);
    setTimeout(hideLoading, 500);
  };

  return (
    <StyledDashboard style={{ overflowY: "auto", overflowX: "hidden" }}>
      <Splash></Splash>
      <Spin spinning={loading} size="large" style={{ top: "250px" }}>
        <Navbar setGlobalLanguage={setGlobalLanguage}></Navbar>

        <Routes>
          <Route
            path="/"
            element={
              <div
                id="content-section"
                className="content"
                style={{ overflowY: "auto", overflowX: "hidden" }}>
                <section id="home">
                  <Home></Home>
                </section>
                <section id="about-us">
                  <AboutUs></AboutUs>
                </section>
                <section id="services">
                  <Services></Services>
                </section>
                <section id="contact-us">
                  <ContactUs
                    language={language}
                    spinningState={spinningState}></ContactUs>
                </section>
              </div>
            }
          />
          {/* Service Route START */}
          <Route
            path="/mobile-service"
            element={<MobileService title="HOME PAGE" />}
          />
          <Route
            path="/website-service"
            element={<WebsiteService title="HOME PAGE" />}
          />
          <Route
            path="/design-service"
            element={<DesignService title="HOME PAGE" />}
          />
          <Route
            path="/consultant-service"
            element={<ConsultantService title="HOME PAGE" />}
          />
          {/* Service Route END */}
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy title="HOME PAGE" />}
          />
          <Route path="/faq" element={<FAQ title="HOME PAGE" />} />

          <Route path="*" exact={true} element={<NotFound />} />
        </Routes>
        <CompanyFooter language={language}></CompanyFooter>
      </Spin>
      <FloatButton.BackTop
        icon={
          <ArrowUpOutlined
            style={{ fontSize: "20px", color: "var(--dark-color)" }}
          />
        }
        visibilityHeight={500}
      />
    </StyledDashboard>
  );
}

export default App;

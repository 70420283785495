import React from "react";
import { Card, Row, Col } from "antd";
import strings from "./services/Localization/Language";
import { motion } from "framer-motion";
import ServiceCard from "./component/service/ServiceCard";
import { ServiceTypeEnum } from "./services/enum";

import "./Services.scss";

const { Meta } = Card;

const Services = () => {
  return (
    <div initial="offscreen" whileInView="onscreen" viewport={{ amount: 0.8 }}>
      <div className="services-intro-background">
        <Row className="services-intro">
          <Col span={24}>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}
            >
              <h3 className="services-subtitle">{strings.services_subtitle}</h3>
            </motion.div>
          </Col>
          <Col md={12} xs={20}>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}
            >
              <h1 className="services-title">{strings.services_title}</h1>
            </motion.div>
          </Col>
          <Col md={13} xs={20}>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              viewport={{ fallback: true }}
            >
              <p className="services-desc">{strings.services_desc}</p>
            </motion.div>
          </Col>
        </Row>
      </div>
      <div className="services-background">
        <Row className="services">
          <Col span={20} className="services-text">
            <motion.div
              initial={{ opacity: 0, translateY: 100 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              viewport={{ fallback: true }}
            >
              <h1 className="services-title">{strings.services_offer}</h1>
            </motion.div>
          </Col>
          <Col span={20} className="services-text">
            <motion.div
              initial={{ opacity: 0, translateY: 100 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              viewport={{ fallback: true }}
            >
              <div>{strings.services_offer_desc}</div>
            </motion.div>
          </Col>
          <Col md={20} xs={24}>
            <motion.div
              initial={{ opacity: 0, translateY: 100 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              viewport={{ fallback: true }}
              className="service-type-motion"
            >
              <Row>
                <Col className="service-types" md={12} xs={24}>
                  <a href="/website-service">
                    <ServiceCard
                      serviceType={ServiceTypeEnum.WEB_DEV}
                    ></ServiceCard>
                  </a>
                </Col>
                <Col className="service-types" md={12} xs={24}>
                  <a href="/mobile-service">
                    <ServiceCard
                      serviceType={ServiceTypeEnum.MOBILE_DEV}
                    ></ServiceCard>
                  </a>
                </Col>
                <Col className="service-types" md={12} xs={24}>
                  <a href="/design-service">
                    <ServiceCard
                      serviceType={ServiceTypeEnum.GRAPH_DESIGN}
                    ></ServiceCard>
                  </a>
                </Col>
                <Col className="service-types" md={12} xs={24}>
                  <a href="/consultant-service">
                    <ServiceCard
                      serviceType={ServiceTypeEnum.CONSULT}
                    ></ServiceCard>
                  </a>
                </Col>
              </Row>
            </motion.div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Services;

let visionMissionText = {
  en: {
    vision_title: "Our Vision",
    vision_desc:
      "Become a strong foundation for the growth and development of society through digital solutions.",
    mission_title: "Our Mission",
    mission_desc:
      "To become a field for activities that enrich the artistic, intellectual and business world through the creation of new ideas, virtuous language and pleasing visual appearance.\n\nCreate new ways for people to interact with technology and solve their problems through our software design.",
  },
  id: {
    vision_title: "Visi Kami",
    vision_desc:
      "Menjadi pondasi yang kuat untuk pertumbuhan dan perkembangan masyarakat melalui solusi digital.",
    mission_title: "Misi Kami",
    mission_desc:
      "Menjadi lahan kegiatan yang memperkaya khazanah artistik, intelektual, dan dunia bisnis melalui peningkatan ide-ide baru, bahasa, dan tampilan visual yang baik.\n\nMenciptakan cara - cara baru bagi masyarakat untuk dapat berinteraksi dengan teknologi dan membuat pemecahan masalah melalui teknologi",
  },
};
export default visionMissionText;

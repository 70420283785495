import styled from "styled-components";

const StyledDashboard = styled.div`
  background-color: var(--quaternary-color);
  .highlightText {
    background-color: var(--primary-color);
  }
  .ant-menu-title-content {
    text-align: center;
    display: block;
    width: 112px;
  }
  .w-100 {
    width: 100%;
  }
  .mt-25 {
    margin-top: 25px;
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
    border-bottom-color: var(--white-color);
  }
  .ant-menu-light
    .ant-menu-item:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ),
  :where(.css-dev-only-do-not-override-1e67qka).ant-menu-light
    .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ) {
    color: var(--white-color);
    font-weight: bold;
  }
  .menuBar {
    position: fixed;
    overflow: hidden !important;
    width: 100%;
    background-color: var(--quaternary-color);
    z-index: 3;
    top: 0px;
    padding: 0 20px;
    overflow: auto;

    display: flex;
    justify-content: space-between;
  }
  .logoMobile {
    display: none;
  }
  .logoDesktop {
    width: 150px;
    float: left;
  }
  .logoDesktop a {
    display: inline-block;
    font-size: 20px;
    padding: 19px 20px;
    text-transform: capitalize;
  }
  .menuCon {
    width: calc(100% - 150px);
    float: left;
  }
  .menuCon .ant-menu-item {
    padding: 0px 5px;
  }
  .menuCon .ant-menu-submenu-title {
    padding: 10px 20px;
  }
  .menuCon .ant-menu-item a,
  .menuCon .ant-menu-submenu-title a {
    padding: 10px 15px;
  }
  .menuCon .ant-menu-horizontal {
    border-bottom: none;
    color: var(--white-color);
    background-color: transparent;
  }
  .menuCon .leftMenu {
    float: left;
  }
  .menuCon .rightMenu {
    float: right;
    padding: 19px 20px;
  }

  .ant-menu-item-selected::after {
    color: var(--primary-color);
  }
  .ant-menu-item-selected {
    color: var(--primary-color) !important;
  }
  .ant-menu-item-selected::after {
    border-bottom-color: var(--primary-color) !important;
  }
  .barsMenu {
    float: right;
    height: 32px;
    margin-right: 40px;
    padding: 6px;
    margin-top: 8px;
    display: none;
    background: none;
  }
  .barsBtn {
    display: block;
    width: 20px;
    height: 2px;
    background: #1890ff;
    position: relative;
  }
  .barsBtn:after,
  .barsBtn:before {
    content: attr(x);
    width: 20px;
    position: absolute;
    top: -6px;
    left: 0;
    height: 2px;
    background: #1890ff;
  }
  .barsBtn:after {
    top: auto;
    bottom: -6px;
  }

  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
    width: 100%;
  }
  .ant-drawer-body .ant-menu-horizontal {
    border-bottom: none;
  }
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom-color: transparent;
  }

  .ant-spin {
    position: fixed !important;
    top: 30% !important;
  }

  @media (max-width: 815px) {
    .logoMobile {
      display: block;
    }
    .barsMenu {
      display: inline-block;
    }
    .language-popover,
    .leftMenu,
    .rightMenu {
      display: none;
    }
    .menuCon .ant-menu-item,
    .menuCon .ant-menu-submenu-title {
      padding: 1px 20px;
    }
    .logoDesktop {
      display: none;
    }
  }
  .ant-dropdown-placement-bottomRight {
    right: 0px;
    top: 52px;
  }
  .ant-select-selector,
  .ant-select {
    color: rgba(0, 0, 0, 0.88);
  }
  .footerText:hover,
  .md-title:hover,
  .services-title:hover,
  .visimisi-title:hover,
  .aboutus-title:hover,
  .aboutus-subtitle:hover,
  .home-title:hover {
    color: var(--primary-color) !important;
    text-decoration: none !important;
  }
  .footerText,
  .md-title,
  .services-title,
  .visimisi-title,
  .aboutus-title,
  .aboutus-subtitle,
  .home-title {
    -webkit-transition: color 1s; /* For Safari 3.0 to 6.0 */
    transition: color 1s;
  }
  .ant-fade-enter-active {
    display: block;
  }

  .ant-fade-leave-active {
    display: none;
  }

  .ant-float-btn-circle {
    margin-right: 10px;
    margin-bottom: 50px;
  }
`;

const StyledAboutUs = styled.div`
    mark{
        padding: 0 0.3em;
    }
    width: 100%;
    display: flex;
    background-color: #F1EFEF;
    .titleWhy{
        position: absolute;
        left: 950px;
        font-size: 9vw;
        line-height: 81.02%;
        padding-top: 15px;
        font-weight: 700;
    }
    .descWhy{
        position: absolute;
        left: 1100px;
        font-size: 2vw;
        padding-top: 20%;
        line-height: 190.52%;
        max-width: 400px;
    }
    .dotWhy{
        position: absolute;
        right: 100px;
        bottom: 100px;
    }
    .titleHow{
        left: 100px;
        position: absolute;
        font-size: 9vw;
        line-height: 81.02%;
        padding-top: 15px;
    }
    .descHow{
        width: 70%;
        left: 120px;
        position: absolute;
        font-size: 2vw;
        padding-top: 250px;
        line-height: 190.52%;
    }
    .triangleHow{
        position: absolute;
        left: 100px;
        bottom: 50px;
    }
    .imgHow{
        float: right;
        width: 50vw;
    }
    .titleWhat{
        position: absolute;
        left: 750px;
        font-size: 9vw;
        line-height: 81.02%;
        padding-top: 200px;
    }
    .descWhat{
        position: absolute;
        left: 850px;
        font-size: 2vw;
        padding-top: 23%;
        line-height: 190.52%;
    }
    .triangleWhat{
        position: absolute;
        right: 700px;
    }
    .dotWhat{
        position: absolute;
        right: 300px;
        padding-top: 200px;
    }
    .ellipseWhat{
        position: absolute;
        right: 0px;
        padding-top: 320px;
    }
    .imgWhat{
        float: right;
    }
    @media (max-width:1376px)  {
        .titleWhy, .descWhy, .titleHow, .descHow {
            text-align: left;
            left: 25%;
            margin-top: 0px;
            top: 15%;
        }
        .descWhy, .descHow, .descWhat{
            font-size: 15px;
        }
        .dotWhy, .triangleHow, .ellipseWhat, .dotWhat, .triangleWhat{
            display: none;
        }
        .titleWhy{
            padding-top: 0px;
        }
        .imgWhy, .imgHow, .imgWhat{
            width: 100vw;
            opacity: 0.6;
        }
        .titleWhat, .descWhat{
            left: 25%;
        }
        .titleHow, .titleWhat{
            padding-top: 0px;
            margin-top: 15%;
            z-index: 5;
        }
        .descHow, .descWhat{
            z-index: 5;
            padding-top: 30%;
        }
        .mt-25{
            margin-top: 0px;
        }
        .ant-col-12{
            flex: 0 0 100%;
            max-width: 100%;
        }
`;

export { StyledDashboard, StyledAboutUs };

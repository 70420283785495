let homeText = {
  en: {
    home_title: "You Deserve Wonderful Software",
    home_subtitle: "You're Awesome!",
    home_desc:
      "We’re experts in creating mobile apps, web solutions, and graphic design for any needs. Got any crazy ideas for it? Share with us, together we can light up your ideas!",
    home_CTA: "Tell us your ideas",
  },
  id: {
    home_title: "Kamu Berhak Mendapatkan Software Terbaik",
    home_subtitle: "Kamu Luar Biasa!",
    home_desc:
      "Kami berpengalaman dalam membuat aplikasi mobile, website, dan graphic design untuk kebutuhan apapun. Punya ide gila? Segera beritahu kami, together we can light up your ideas!",
    home_CTA: "Beritahu kami idemu",
  },
};

export default homeText;

import React from "react";
import { Button, Row, Col } from "antd";
import strings from "./services/Localization/Language";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <div
      className="home-background"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8 }}
    >
      <Row className="home">
        <Col span={24}>
          <motion.div
            initial={{ opacity: 0, translateX: 100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ fallback: true }}
          >
            <h3 className="home-subtitle">{strings.home_subtitle}</h3>
          </motion.div>
        </Col>
        <Col md={12} xs={20}>
          <motion.div
            initial={{ opacity: 0, translateX: 100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ fallback: true }}
          >
            <h1 className="home-title">{strings.home_title}</h1>
          </motion.div>
        </Col>
        <Col md={13} xs={20}>
          <motion.div
            initial={{ opacity: 0, translateX: 100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ fallback: true }}
          >
            <p className="home-desc">{strings.home_desc}</p>
            <Button
              id="gtm-contact-us"
              className="button-home"
              type="primary"
              onClick={() => (window.location.href = "#contact-us")}
            >
              {strings.home_CTA}
            </Button>
          </motion.div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;

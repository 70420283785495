import axios from "axios";
// import Modal from "../component/reusable/modal";

const sendEmail = async (messageForm, language) => {
  const requestingData = messageForm;
  var result = {};
  if (!language) {
    language = "en";
  }
  // Axios gabisa return error, result jadi undefined. Kalo success, dia bisa send object
  result = await axios({
    method: "POST",
    url: "https://api.lumiiumtech.com/api/send-email", // url
    data: requestingData,
    params: { language: language },
  }).catch((e) => {
    return "failed";
  });
  return result;
};
export default sendEmail;

import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "antd";
import FAQTable from "./component/faq/faq-table";
import { ServiceTypeEnum } from "./services/enum";

import languageVar from "./services/Localization/Language";

import "./FAQ.scss";

const { Panel } = Collapse;
var faqSectionTitle = {
  website: "Website Development",
  mobile: "Mobile Development",
  design: "Graphic Design",
  consult: "Consultation",
};
function FAQ() {
  function renderFAQSectionTitle(type) {
    switch (type) {
      case ServiceTypeEnum.WEB_DEV:
        return faqSectionTitle.website;
      case ServiceTypeEnum.MOBILE_DEV:
        return faqSectionTitle.mobile;
      case ServiceTypeEnum.GRAPH_DESIGN:
        return faqSectionTitle.design;
      case ServiceTypeEnum.CONSULT:
        return faqSectionTitle.consult;
    }
  }
  function renderFAQTable(type) {
    switch (type) {
      case ServiceTypeEnum.WEB_DEV:
        return languageVar.web_faq_items.map((item, index) => (
          <FAQTable
            key={index + 1}
            title={item.header}
            text={item.text}
          ></FAQTable>
        ));

      case ServiceTypeEnum.MOBILE_DEV:
        return languageVar.mob_faq_items.map((item, index) => (
          <FAQTable
            key={index + 1}
            title={item.header}
            text={item.text}
          ></FAQTable>
        ));

      case ServiceTypeEnum.GRAPH_DESIGN:
        return languageVar.design_faq_items.map((item, index) => (
          <FAQTable
            key={index + 1}
            title={item.header}
            text={item.text}
          ></FAQTable>
        ));

      case ServiceTypeEnum.CONSULT:
        return languageVar.consult_faq_items.map((item, index) => (
          <FAQTable
            key={index + 1}
            title={item.header}
            text={item.text}
          ></FAQTable>
        ));
    }
  }
  return (
    <>
      <Row className="faq">
        <Col className="faq-title" span={24}>
          <h1>FAQ</h1>
          <p>Frequently Asked Questions.</p>
          <p className="faq-desc">{languageVar.faq.desc}</p>
        </Col>
        <Col md={12} xs={24}>
          <h3 className="faq-section-title">General</h3>
        </Col>
        <Col md={12} xs={24}>
          {languageVar.general_faq_items.map((item, index) => (
            <FAQTable
              key={index + 1}
              title={item.header}
              text={item.text}
            ></FAQTable>
          ))}
        </Col>

        <Col className="faq-service-section" md={12} xs={24}>
          <h3 className="faq-section-title">
            {renderFAQSectionTitle(ServiceTypeEnum.WEB_DEV)}
          </h3>
        </Col>
        <Col className="faq-service-section" md={12} xs={24}>
          {renderFAQTable(ServiceTypeEnum.WEB_DEV)}
        </Col>

        <Col className="faq-service-section" md={12} xs={24}>
          <h3 className="faq-section-title">
            {renderFAQSectionTitle(ServiceTypeEnum.MOBILE_DEV)}
          </h3>
        </Col>
        <Col className="faq-service-section" md={12} xs={24}>
          {renderFAQTable(ServiceTypeEnum.MOBILE_DEV)}
        </Col>

        <Col className="faq-service-section" md={12} xs={24}>
          <h3 className="faq-section-title">
            {renderFAQSectionTitle(ServiceTypeEnum.GRAPH_DESIGN)}
          </h3>
        </Col>
        <Col className="faq-service-section" md={12} xs={24}>
          {renderFAQTable(ServiceTypeEnum.GRAPH_DESIGN)}
        </Col>

        <Col className="faq-service-section" md={12} xs={24}>
          <h3 className="faq-section-title">
            {renderFAQSectionTitle(ServiceTypeEnum.CONSULT)}
          </h3>
        </Col>
        <Col className="faq-service-section" md={12} xs={24}>
          {renderFAQTable(ServiceTypeEnum.CONSULT)}
        </Col>
      </Row>
    </>
  );
}
export default FAQ;

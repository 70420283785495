import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPReviewInfo() {
  return (
    <>
      <h3>
        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h3>
      <p>
        You have the right to request access to the personal information we
        collect from you, change that information, or delete it. To request to
        review, update, or delete your personal information, please submit a
        request form by clicking{" "}
        <a href="https://app.termly.io/notify/db1adf2f-770c-4dfc-bc72-78962067d419">
          here
        </a>
        .
      </p>
    </>
  );
}
export default PPReviewInfo;

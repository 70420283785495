import React from "react";
import { Button, Row, Col } from "antd";
import strings from "./services/Localization/Language";
import { motion } from "framer-motion";

const NotFound = () => {
  return (
    <div
      className="not-found-background"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8 }}
    >
      <Row className="not-found">
        <Col span={24}>
          <motion.div
            initial={{ opacity: 0, translateY: 100 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            viewport={{ fallback: true }}
          >
            <img
              src={require("./assets/gif/not-found.gif")}
              className="lottiefilesImg"
            />
          </motion.div>
        </Col>
        <Col span={12}>
          <motion.div
            initial={{ opacity: 0, translateY: 100 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            viewport={{ fallback: true }}
          >
            <p>{strings.not_found_desc}</p>
          </motion.div>
        </Col>
        <Col span={24}>
          <motion.div
            initial={{ opacity: 0, translateY: 100 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            viewport={{ fallback: true }}
          >
            <Button
              className="button-home"
              type="primary"
              onClick={() => (window.location.href = "/")}
            >
              {strings.not_found_button}
            </Button>
          </motion.div>
        </Col>
      </Row>
    </div>
  );
};

export default NotFound;

import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPMinorInfo() {
  return (
    <>
      <h3>7. DO WE COLLECT INFORMATION FROM MINORS?</h3>
      <p>
        <i>
          <b>In Short:</b> We do not knowingly collect data from or market to
          children under 18 years of age.
        </i>
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Services. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at
        wecare@cs.lumiiumtech.com.
      </p>
    </>
  );
}
export default PPMinorInfo;

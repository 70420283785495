import React from "react";
import { Button, Row, Col, Card } from "antd";
import { motion } from "framer-motion";
import {
  ArrowRightOutlined,
  RiseOutlined,
  SmileOutlined,
  SelectOutlined,
  PieChartOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import languageVar from "./services/Localization/Language";
import { WebAdvantages, ServiceTypeEnum } from "./services/enum";
import SoftwareDevelopmentTool from "./SoftwareDevelopmentTool";
import FAQServices from "./component/faq/faq-services/faq-services";
import PricingCards from "./component/pricing/pricing-card";
import AnotherServices from "./AnotherServices";

const WebsiteService = () => {
  return (
    <div
      className="detail-service-background"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8 }}>
      <Row className="detail-service">
        <Col xl={16} md={24}>
          <motion.div
            initial={{ opacity: 0, translateX: 100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ fallback: true }}>
            <h1 className="home-title">{languageVar.website_service_title}</h1>
            <div className="service-img-mobile">
              <img
                src={require("./assets/service/web.png")}
                className="detail-consultant-service"
                alt="web"
              />
            </div>
            <p>{languageVar.website_service_desc}</p>
            <p style={{ marginTop: "15px" }}>
              {languageVar.website_service_subdesc}
            </p>
            <p className="service-detail-message">
              {languageVar.service_detail_message}
            </p>
            <Row className="list-detail-service">
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Single landing page</span>
              </Col>

              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Multiple landing page</span>
              </Col>

              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Web-based application</span>
              </Col>

              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Website UI/UX design</span>
              </Col>
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Website maintenance</span>
              </Col>
              <Col span={24}>
                <CheckSquareOutlined className="checkbox-icon" />
                <span>Website QA</span>
              </Col>
              <Col span={24}>
                <Button
                  id="gtm-contact-us"
                  className="button-home"
                  type="primary"
                  onClick={() => (window.location.href = "/#contact-us")}>
                  {languageVar.service_detail_quote}
                  <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </motion.div>
        </Col>
        <Col xl={8} md={24} style={{ textAlign: "center" }}>
          <motion.div
            initial={{ opacity: 0, translateX: 100 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            viewport={{ fallback: true }}>
            <div>
              <img
                src={require("./assets/service/web.png")}
                className="service-img"
                alt="web"
              />
            </div>
          </motion.div>
        </Col>
      </Row>
      <Row style={{ marginTop: "3%" }}>
        <Col span={24}>
          <h3 style={{ color: "var(--primary-color)", textAlign: "center" }}>
            {languageVar.service_benefit}
          </h3>
        </Col>
      </Row>
      <Row
        className="detail-service-feature"
        style={{ justifyContent: "center" }}
        gutter={[48, 24]}>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <PieChartOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{WebAdvantages[0]}</span>
          </Card>
        </Col>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <SmileOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{WebAdvantages[1]}</span>
          </Card>
        </Col>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <SelectOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{WebAdvantages[2]}</span>
          </Card>
        </Col>
        <Col xl={4} md={12} className="service-feature-card">
          <Card className="detail-service-card">
            <RiseOutlined style={{ fontSize: "60px" }} />
            <br></br>
            <span>{WebAdvantages[3]}</span>
          </Card>
        </Col>
      </Row>
      <SoftwareDevelopmentTool type={"website"} />
      <section id="web-price" style={{ scrollMargin: "-90px" }}>
        <Row className="detail-service-plan">
          <Col span={24}>
            <PricingCards type={ServiceTypeEnum.WEB_DEV}></PricingCards>
          </Col>
        </Row>
      </section>
      <FAQServices type={ServiceTypeEnum.WEB_DEV}></FAQServices>
      <AnotherServices currentService={ServiceTypeEnum.WEB_DEV} />
    </div>
  );
};

export default WebsiteService;

let mobileServiceText = {
  en: {
    mobile_service_title: "Mobile App Development",
    mobile_service_desc:
      "Tell us what your problem is or what application you need, we will provide the best solution in android or iOS application, or even cross platform. This mobile application can be for personal or enterprise purposes.",
    mobile_service_subdesc:
      "We use the latest technology to design and build the mobile application made to fulfill your needs.",
  },
  id: {
    mobile_service_title: "Mobile App Development",
    mobile_service_desc:
      "Beritahu kami masalah Anda atau aplikasi apa yang sedang Anda butuhkan, kami akan memberikan solusi terbaik melalui aplikasi android atau iOS, atau bahkan keduanya. Aplikasi mobile ini bisa untuk kebutuhan personal hingga bisnis.",
    mobile_service_subdesc:
      "Kami menggunakan teknologi terbaru untuk mendesain dan mengembangkan aplikasi mobile sesuai dengan kebutuhan Anda.",
  },
};

var mobileServicefaqItems = {
  en: {
    mob_faq_items: [
      {
        header: "What type of applications LumiiumTech make?",
        text: `In general we can make any mobile application you need. Some examples of applications include cashier applications, social media, online shops, financial management, health, business support, and many more.\n\nFor mobile application development, please contact us for more information.`,
      },
      {
        header: "How much does it cost to make android and iOS applications?",
        text: `The cost of developing a mobile application varies widely, depending on the complexity, difficulty, features, build time, and the platform. We have estimated the price for you, please click <a href="/mobile-service/#mobile-price">here</a>.\n\nIf none of these details suit your needs, consult with us for an estimate of the costs required.`,
      },
      {
        header:
          "How can I monitor the application development process that I ordered?",
        text: `We will inform you directly when your application development has reached 25%, 50%, 75% and 100% progress.`,
      },
      {
        header: "What needs to be prepared if I want to make an application?",
        text: `What needs to be prepared is an overview of the application you want to make, the purpose of making the application, features, elements, general design, logo, photos/images to be displayed, text or content, and so on.\n\nDon't worry if you don't have the details yet. Consult with us first, and we will do our best to meet your needs.`,
      },
      {
        header:
          "Can LumiiumTech register the application on play store or app store?",
        text: `Certainly. If you want your application to enter the play store or app store, we will help with the registration process.`,
      },
      {
        header: "Is there any tutorial to run the ordered application?",
        text: `We provide step-by-step tutorials for using the application through video and image media. If you are still having trouble operating it, you can contact us at any time.`,
      },
      {
        header:
          "Can LumiiumTech make game applications and how long does it take to build?",
        text: `We’re sorry, at this time we still can't provide game development services.`,
      },
      {
        header:
          "Can LumiiumTech make an online shop application with a payment feature in it?",
        text: `LumiiumTech provides online shop application development services with payment features. Please click <a href="/mobile-service/#mobile-price">here</a> to see estimated costs and development time.`,
      },
    ],
  },
  id: {
    mob_faq_items: [
      {
        header: "Aplikasi apa saja yang LumiiumTech bisa membuatnya?",
        text: `Secara umum kami dapat membuat aplikasi mobile apapun yang Anda butuhkan. Beberapa contoh aplikasinya misalnya aplikasi kasir, sosial media, toko online, pengelola keuangan, kesehatan, pendukung bisnis, dan masih banyak lagi.\n\nUntuk pembuatan aplikasi mobile silahkan hubungi kami untuk informasi lebih lanjut.`,
      },
      {
        header:
          "Berapa biaya yang diperlukan untuk membuat aplikasi android dan iOS?",
        text: `Biaya pembuatan aplikasi mobile sangat bervariasi, tergantung pada kompleksitas, tingkat kesulitan, fitur, waktu pembuatan, dan platformnya. Kami sudah membuat estimasi harganya untuk Anda, silahkan klik <a href="/mobile-service/#mobile-price">disini</a>.\n\nJika dalam rincian tersebut dirasa tidak ada yang sesuai dengan kebutuhan Anda, konsultasikan dengan kami untuk estimasi biaya yang dibutuhkan.`,
      },
      {
        header:
          "Bagaimana agar saya bisa memantau proses pengembangan aplikasi yang saya pesan?",
        text: `Kami akan menginformasikan secara langsung kepada Anda ketika pengembangan aplikasi Anda sudah mencapai progress 25%, 50%, 75%, dan 100%.`,
      },
      {
        header:
          "Apa yang perlu dipersiapkan dalam pengembangan suatu aplikasi?",
        text: `Yang perlu dipersiapkan adalah gambaran besar aplikasi yang ingin dibuat, tujuan pembuatan aplikasi, fitur, elemen, desain umum, logo, foto/gambar yang akan ditampilkan, teks atau konten, dan lain-lain.\n\nJangan khawatir jika Anda belum memiliki gambaran secara detail. Konsultasikan terlebih dulu dengan kami, dan kami akan memberikan yang terbaik untuk memenuhi kebutuhan Anda.`,
      },
      {
        header:
          "Apakah LumiiumTech dapat membantu saya untuk mendaftar ke playstore atau appstore?",
        text: `Tentu bisa. Jika Anda ingin aplikasi Anda masuk ke playstore atau appstore, kami akan membantu untuk proses pendaftarannya.`,
      },
      {
        header: "Apakah ada pelatihan untuk menjalankan aplikasi yang dipesan?",
        text: `Kami menyediakan tutorial penggunaan aplikasi melalui media video dan gambar secara step by step. Jika Anda masih kesulitan untuk mengoperasikannya, Anda dapat menghubungi kami kapanpun.`,
      },
      {
        header:
          "Apakah LumiiumTech bisa membuat aplikasi game dan berapa lama waktu pembuatannya?",
        text: `Sayang sekali untuk saat ini kami masih belum bisa menyediakan layanan pembuatan game.`,
      },
      {
        header:
          "Apakah LumiiumTech bisa membuat aplikasi toko online dengan fitur pembayaran di dalamnya?",
        text: `LumiiumTech menyediakan layanan pembuatan aplikasi online shop dengan fitur pembayaran. Silahkan klik <a href="/mobile-service/#mobile-price">disini</a> untuk melihat estimasi biaya dan waktu pembuatannya.`,
      },
    ],
  },
};

export default { mobileServiceText, mobileServicefaqItems };

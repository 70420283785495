import React, { useState } from "react";
import { Typography, Row, Col } from "antd";
import strings from "../../services/Localization/Language";
import { motion } from "framer-motion";
import {
  LaptopOutlined,
  MobileOutlined,
  MessageOutlined,
  EditOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  ServiceTypeEnum,
  WebAdvantages,
  MobileAdvantages,
  DesignAdvantages,
  ConsultantAdvantages,
} from "../../services/enum";

import "./ServiceCard.scss";

const { Paragraph } = Typography;

const ServiceCard = ({ serviceType }) => {
  const [ellipsis, setEllipsis] = useState(true);

  return (
    <motion.div
      className="card-motion"
      whileHover={{
        scale: 1.05,
        boxShadow: "10px 10px 22px 3px rgba(0,0,0,0.26)",
        transition: { duration: 0.5, ease: "easeInOut" },
      }}
    >
      <div
        className="service-card"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ amount: 0.8 }}
      >
        <Row>
          <Col className="service-card-header-col" span={24}>
            {/* Header Start */}
            <div className="service-card-header">
              <div className="icon-container">
                {(() => {
                  switch (serviceType) {
                    case ServiceTypeEnum.WEB_DEV:
                      return <LaptopOutlined />;
                    case ServiceTypeEnum.MOBILE_DEV:
                      return <MobileOutlined />;
                    case ServiceTypeEnum.GRAPH_DESIGN:
                      return <EditOutlined />;
                    case ServiceTypeEnum.CONSULT:
                      return <MessageOutlined />;
                    default:
                      return null;
                  }
                })()}
              </div>
              <div className="service-card-learn-more">
                Learn more <RightOutlined />
              </div>
            </div>
            {/* Header End */}
          </Col>

          <Col className="service-card-desc-col" span={24}>
            {/* Text Start */}
            {(() => {
              switch (serviceType) {
                case ServiceTypeEnum.WEB_DEV:
                  return (
                    <div>
                      <h1>{strings.website_service_title}</h1>
                      <Paragraph
                        className={"service-card-desc"}
                        ellipsis={
                          ellipsis
                            ? {
                                rows: 2,
                              }
                            : false
                        }
                      >
                        {strings.service_web_dev}
                      </Paragraph>
                    </div>
                  );

                case ServiceTypeEnum.MOBILE_DEV:
                  return (
                    <div>
                      <h1>{strings.mobile_service_title}</h1>
                      <Paragraph
                        className={"service-card-desc"}
                        ellipsis={
                          ellipsis
                            ? {
                                rows: 2,
                              }
                            : false
                        }
                      >
                        {strings.service_mobile_dev}
                      </Paragraph>
                    </div>
                  );

                case ServiceTypeEnum.GRAPH_DESIGN:
                  return (
                    <div>
                      <h1>{strings.design_service_title}</h1>
                      <Paragraph
                        className={"service-card-desc"}
                        ellipsis={
                          ellipsis
                            ? {
                                rows: 2,
                              }
                            : false
                        }
                      >
                        {strings.service_design}
                      </Paragraph>
                    </div>
                  );

                case ServiceTypeEnum.CONSULT:
                  return (
                    <div>
                      <h1>{strings.consultant_service_title}</h1>
                      <Paragraph
                        className={"service-card-desc"}
                        ellipsis={
                          ellipsis
                            ? {
                                rows: 2,
                              }
                            : false
                        }
                      >
                        {strings.service_consult}
                      </Paragraph>
                    </div>
                  );
                default:
                  return null;
              }
            })()}
            {/* Text End */}
          </Col>

          <Col span={8} className="service-advantages-col">
            <div className="service-advantages">
              {(() => {
                switch (serviceType) {
                  case ServiceTypeEnum.WEB_DEV:
                    return (
                      <div>
                        {WebAdvantages[0]} <br />
                        <br />
                        {WebAdvantages[1]} <br />
                        <br />
                        {WebAdvantages[2]} <br />
                        <br />
                        {WebAdvantages[3]} <br />
                        <br />
                      </div>
                    );

                  case ServiceTypeEnum.MOBILE_DEV:
                    return (
                      <div>
                        {MobileAdvantages[0]} <br />
                        <br />
                        {MobileAdvantages[1]} <br />
                        <br />
                        {MobileAdvantages[2]} <br />
                        <br />
                        {MobileAdvantages[3]} <br />
                        <br />
                      </div>
                    );

                  case ServiceTypeEnum.GRAPH_DESIGN:
                    return (
                      <div>
                        {DesignAdvantages[0]} <br />
                        <br />
                        {DesignAdvantages[1]} <br />
                        <br />
                        {DesignAdvantages[2]} <br />
                        <br />
                        {DesignAdvantages[3]} <br />
                        <br />
                      </div>
                    );

                  case ServiceTypeEnum.CONSULT:
                    return (
                      <div>
                        {ConsultantAdvantages[0]} <br />
                        <br />
                        {ConsultantAdvantages[1]} <br />
                        <br />
                        {ConsultantAdvantages[2]} <br />
                        <br />
                        {ConsultantAdvantages[3]} <br />
                        <br />
                      </div>
                    );

                  default:
                    return null;
                }
              })()}
            </div>
          </Col>

          <Col span={16} className="service-img-col">
            {/* img Start */}

            {(() => {
              switch (serviceType) {
                case ServiceTypeEnum.WEB_DEV:
                  return (
                    <img
                      src={require("../../assets/service/web.png")}
                      className="service-card-img"
                      alt="web"
                    />
                  );
                case ServiceTypeEnum.MOBILE_DEV:
                  return (
                    <img
                      src={require("../../assets/service/mobile.png")}
                      className="service-card-img mobile"
                      alt="mobile"
                    />
                  );
                case ServiceTypeEnum.GRAPH_DESIGN:
                  return (
                    <img
                      src={require("../../assets/service/design.png")}
                      className="service-card-img"
                      alt="design"
                    />
                  );
                case ServiceTypeEnum.CONSULT:
                  return (
                    <img
                      src={require("../../assets/service/consult.png")}
                      className="service-card-img"
                      alt="consult"
                    />
                  );
                default:
                  return null;
              }
            })()}
            {/* img End */}
          </Col>
        </Row>
      </div>
    </motion.div>
  );
};

export default ServiceCard;

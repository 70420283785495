import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

function PPCookiesInfo() {
  return (
    <>
      <h3>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
      <p>
        <i>
          <b>In Short:</b> We may use cookies and other tracking technologies to
          collect and store your information.
        </i>
      </p>
      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </p>
    </>
  );
}
export default PPCookiesInfo;

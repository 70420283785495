let ConsultantServiceText = {
  en: {
    consultant_service_title: "Consultant",
    consultant_service_desc:
      "Have a problem with digitalization? We can help you with consulting services consisting of discussing needs to systems design (tech stack, data flow, user stories, and others). We have experienced and trusted teams to provide solutions to your problems.",
    consultant_service_subdesc:
      "We are pleased if given the opportunity to work together and solve your problem.",
  },
  id: {
    consultant_service_title: "Konsultasi",
    consultant_service_desc:
      "Punya masalah yang berkaitan dengan digitalisasi? Kami bisa membantumu dengan layanan konsultasi mulai dari diskusi kebutuhan hingga mendesain sistem (tech stack, data flow, user story, dan lainnya). Kami memiliki tim yang berpengalaman dan terpercaya untuk memberi solusi atas masalahmu.",
    consultant_service_subdesc:
      "Kami sangat senang jika diberi kesempatan untuk bekerja bersama-sama dan memberi solusi atas masalah Anda.",
  },
};

var ConsultServicefaqItems = {
  en: {
    consult_faq_items: [
      {
        header: "Consultations are carried out through what media?",
        text: `For now, consultations are carried out using online media such as zoom meetings where there will be a consultant from LumiiumTech who will serve you online. You can convey your needs and problems to our consultants. Then we will provide a solution according to your needs.`,
      },
      {
        header: "What is the benefit by using consulting services?",
        text: `The products you get depend on the package you choose, including App UX, User Flow, Design Notice, App Technology, and Infrastructure Design.\n\nHowever, if you need other products from the consultation besides those mentioned above, please discuss them with us immediately.`,
      },
      {
        header: "What needs to be prepared before conducting a consultation?",
        text: `Several things that you can prepare before conducting a consultation such as business objectives, app objectives, app user personas, and features (including functional and non-functional requirements).\n\nDon't worry if you still don't have these things or you're still unsure. Please contact us first to discuss.`,
      },
    ],
  },
  id: {
    consult_faq_items: [
      {
        header: "Konsultasi dilakukan melalui media apa saja?",
        text: `Untuk saat ini konsultasi dilakukan dengan media online seperti zoom meeting dimana akan ada konsultan dari LumiiumTech yang akan melayani Anda secara online. Anda bisa menyampaikan kebutuhan dan masalah Anda kepada konsultan kami. Kemudian kami akan memberikan solusi sesuai kebutuhan Anda.`,
      },
      {
        header:
          "Apa saja yang akan didapatkan dengan menggunakan jasa konsultasi?",
        text: `Produk yg didapatkan bergantung dengan paket yg Anda pilih diantaranya seperti App UX, User Flow, Design Notice, App Technology, dan Infrastructure Design.\n\nNamun jika Anda membutuhkan produk lain dari konsultasi selain yang disebutkan di atas, segera diskusikan dengan kami.`,
      },
      {
        header: "Apa yang perlu dipersiapkan sebelum melakukan konsultasi?",
        text: `Beberapa hal yang dapat Anda persiapkan sebelum melakukan konsultasi seperti tujuan bisnis, tujuan aplikasi, app user personas, dan fitur-fitur (termasuk functional dan non-functional requirements).\n\nNamun jangan khawatir jika Anda masih belum memiliki hal-hal tersebut atau Anda masih belum yakin. Segera hubungi kami terlebih dahulu untuk berdiskusi.`,
      },
    ],
  },
};

export default { ConsultantServiceText, ConsultServicefaqItems };

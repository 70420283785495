let pricingMobileText = {
  en: {
    pricing_mobile: {
      header_type: "Application Type",
      header_price: "Price (Starts From)",
      header_cross: "Cross Platform",

      eshope: "Online Shop/Catalogue",
      health: "Health/Fitness",
    },
  },
  id: {
    pricing_mobile: {
      header_type: "Jenis Aplikasi",
      header_price: "Harga (Mulai Dari)",
      header_cross: "Lintas Platform",

      eshope: "Online Shop/Katalog",
      health: "Health/Fitness",
    },
  },
};

export default pricingMobileText;
